import { registerRootComponent } from 'expo';

import App from './App';
import { Settings } from 'react-native-fbsdk-next';
import { Platform } from 'react-native';
if (Platform.OS != "web") {
    Settings.setAppID('1532323771006508');
}
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
