import { Alert, Platform, StyleSheet, TouchableOpacity, View,useWindowDimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import { FontAwesome } from "@expo/vector-icons";
import i18n from "i18n-js";
import { FormControl, Select, CheckIcon, Modal, ScrollView } from "native-base";
import CustomText from '../components/CustomText';
import Loader from '../components/Loader';
import { connect } from 'react-redux';
import * as actions from "../redux/actions/index"
import { AntDesign, Feather } from '@expo/vector-icons';
import sliceText from "../utils/sliceText"
import { responsiveWidth } from 'react-native-responsive-dimensions';
import CreateListing from './CreateListing'
import constants from "../myConfig/constants";

const COUNT = 8
function MyListing({ user, garageListing, getGarageListing, deleteListing }) {
    const [loading, setLoading] = useState(true)
    const [modalVisible, setModalVisible] = useState(false)
    const [current, setCurrent] = useState({})
    const [page, setPage] = useState(1)
    const [listing, setListing] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [deletLoading, setDeleteLoading] = useState(false)
    const { width } = useWindowDimensions()

    const [filters, setFilters] = useState({
        sortBy: "desc",
        title: "",
        status: "",
        active: "",
        city: "",
        block: ""
    })


    useEffect(() => {
        setLoading(true)
        getLisitng()
    }, [])

    useEffect(() => {
        if (garageListing?.listings?.data) {
            setListing(garageListing?.listings?.data)
        }
    }, [garageListing])

    function getLisitng() {
        getGarageListing({
            ...filters,
            user: user?.garage?.seller?.garage_name
        })
            .then(() => setLoading(false))
    }

    function onDelete(id) {
        Alert.alert(
            'Are you sure?',
            'You want to delete this listing?',
            [
                {
                    text: 'Cancel',
                    style: 'cancel',
                },
                {
                    text: 'Yes',
                    onPress: () => {
                        deleteListing(id).then(() => {
                            getLisitng()
                        })
                    },
                },
            ]
        );
    }

    function onDeleteMult() {
        Alert.alert(
            'Are you sure?',
            'You want to delete this selected listing?',
            [
                {
                    text: 'Cancel',
                    style: 'cancel',
                },
                {
                    text: 'Yes',
                    onPress: () => {
                        setDeleteLoading(true)
                        const promises = selectedList.map(it => deleteListing(it))
                        Promise.all(promises)
                            .then(() => {
                                getLisitng()
                                setDeleteLoading(false)
                                setSelectedList([])
                            })
                            .catch(()=>{
                                setDeleteLoading(false)
                            })
                    },
                },
            ]
        );
    }

    if (loading) {
        return <Loader />
    }
    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: '90%', alignSelf: 'center' }}>
                <View style={{ flexDirection: 'row', marginTop: 10 }}>

                    <TouchableOpacity
                        disabled={false}
                        style={{
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            backgroundColor: 'white',
                            marginHorizontal: 5,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderColor: 'lightgray',
                            borderWidth: 1,
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.23,
                            shadowRadius: 2.62,

                            elevation: 4,
                        }}>
                        <FontAwesome name="filter" size={24} color="black" />
                    </TouchableOpacity>

                    <View style={{ marginHorizontal: 5 }}>
                        <FormControl>
                            <Select
                                selectedValue={filters.status}
                                onValueChange={(v) => setFilters({ ...fields, status: v })}
                                accessibilityLabel={i18n.t('STATUS')}
                                placeholder={i18n.t('STATUS')}
                                placeholderTextColor={"black"}
                                style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light' }}
                                fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                                marginY={0.35}
                                fontSize={14}
                                borderRadius={30}
                                minWidth={120}
                                backgroundColor="white"
                                height={39.9}
                                shadow={Platform.OS == "web" ? "none" : "1"}
                                _selectedItem={{
                                    bg: "teal.600",
                                    endIcon: <CheckIcon size={5}
                                    />
                                }}>
                                <Select.Item
                                    _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }}
                                    key={"approved"}
                                    label={i18n.t('APPROVED')}
                                    value={"approved"} />
                                <Select.Item
                                    _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }}
                                    key={"pending"}
                                    label={i18n.t('PENDING')}
                                    value={"pending"} />
                                <Select.Item
                                    _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }}
                                    key={"rejected"}
                                    label={i18n.t('REJECT')}
                                    value={"rejected"} />
                            </Select>
                        </FormControl>
                    </View>
                    <View style={{ marginHorizontal: 5 }}>
                        <FormControl>
                            <Select
                                selectedValue={filters.city}
                                onValueChange={(v) => setFilters({ ...fields, city: v })}
                                accessibilityLabel={i18n.t('S3')}
                                placeholder={i18n.t('CITY')}
                                placeholderTextColor={"black"}
                                style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light' }}
                                fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                                marginY={0.35}
                                fontSize={14}
                                borderRadius={30}
                                minWidth={120}
                                backgroundColor="white"
                                height={39.9}
                                shadow={Platform.OS == "web" ? "none" : "1"}
                                _selectedItem={{
                                    bg: "teal.600",
                                    endIcon: <CheckIcon size={5}
                                    />
                                }}>
                                <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY1')} label={i18n.t('CITY1')} value={"Abu Dhabi"} />
                                <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY2')} label={i18n.t('CITY2')} value={"Al Ain"} />
                                <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY3')} label={i18n.t('CITY3')} value={"Ajman"} />
                                <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY4')} label={i18n.t('CITY4')} value={"Dubai"} />
                                <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY5')} label={i18n.t('CITY5')} value={"Fujairah"} />
                                <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY6')} label={i18n.t('CITY6')} value={"Sharjah"} />
                                <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY7')} label={i18n.t('CITY7')} value={"Ras Al Khaimah"} />
                            </Select>
                        </FormControl>
                    </View>
                </View>
            </View>
            <View style={{ width: '90%', alignSelf: 'center', flex: 1 }}>
                <View style={{ flex: 1 }}>
                    <View>
                        {
                            garageListing?.listings?.data?.length > 0 ? (
                                <ScrollView horizontal={true}>
                                    <View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'lightgray', paddingHorizontal: 5, paddingVertical: 5, marginTop: 10, borderRadius: 5 }}>
                                            <View style={{  width:Platform.OS == "web" && width > constants.BREAK_POINT_1?50:responsiveWidth(8) }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setSelectedList(garageListing?.listings?.data.slice((page - 1) * COUNT, ((page - 1) * COUNT) + COUNT).map(item => item?.id))
                                                    }}
                                                >
                                                    <View style={{ width: 12, height: 12, backgroundColor: 'white', borderRadius: 2, justifyContent: 'center', alignItems: 'center' }}>
                                                        {selectedList.length == garageListing?.listings?.data.slice((page - 1) * COUNT, ((page - 1) * COUNT) + COUNT).length && <AntDesign name='check' size={9} />}
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ width:Platform.OS == "web" && width > constants.BREAK_POINT_1?100:responsiveWidth(10) }}>
                                                <CustomText text={i18n.t('NUM')} style={{ fontSize: 11, }} />
                                            </View>
                                            <View style={{ width:Platform.OS == "web" && width > constants.BREAK_POINT_1?150: responsiveWidth(15) }}>
                                                <CustomText text={i18n.t('PRICE')} style={{ fontSize: 11, }} />
                                            </View>
                                            <View style={{  width:Platform.OS == "web" && width > constants.BREAK_POINT_1?(width-700-constants.DRAWER_WIDTH-(((width-constants.DRAWER_WIDTH)*0.1))):responsiveWidth(35) }}>
                                                <CustomText text={i18n.t('LISTING_TITLE')} style={{ fontSize: 11, }} />
                                            </View>
                                            <View style={{  width:Platform.OS == "web" && width > constants.BREAK_POINT_1?150:responsiveWidth(20) }}>
                                                <CustomText text={i18n.t('CITY')} style={{ fontSize: 11, }} />
                                            </View>
                                            <View style={{ width:Platform.OS == "web" && width > constants.BREAK_POINT_1?150: responsiveWidth(20) }}>
                                                <CustomText text={i18n.t('STATUS')} style={{ fontSize: 11, }} />
                                            </View>
                                            <View style={{ width:Platform.OS == "web" && width > constants.BREAK_POINT_1?100:responsiveWidth(15) }}>
                                                <CustomText text={i18n.t('ACTIONS')} style={{ fontSize: 11, }} />
                                            </View>
                                        </View>
                                        {
                                            garageListing?.listings?.data.slice((page - 1) * COUNT, ((page - 1) * COUNT) + COUNT)?.map((it, i) => {
                                                const selected = selectedList.find(item => item == it?.id)
                                                return (
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', paddingHorizontal: 5, paddingVertical: 10, marginTop: 2, borderRadius: 5 }}>
                                                        <View style={{  width:Platform.OS == "web" && width > constants.BREAK_POINT_1?50:responsiveWidth(8) }}>
                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    if (!selected) {
                                                                        setSelectedList([...selectedList, it?.id])
                                                                    } else {
                                                                        setSelectedList(selectedList.filter(item => item != selected))
                                                                    }
                                                                }}
                                                                hitSlop={3}
                                                            >
                                                                <View style={{ width: 12, height: 12, backgroundColor: 'lightgray', borderRadius: 2, justifyContent: 'center', alignItems: 'center' }}>
                                                                    {selected && <AntDesign name='check' size={9} />}
                                                                </View>
                                                            </TouchableOpacity>
                                                        </View>
                                                        <View style={{ width:Platform.OS == "web" && width > constants.BREAK_POINT_1?100:responsiveWidth(10) }}>
                                                            <CustomText text={i + 1} style={{ fontSize: 9.5, marginLeft: 8 }} />
                                                        </View>
                                                        <View style={{ width: Platform.OS == "web" && width > constants.BREAK_POINT_1?150:responsiveWidth(15) }}>
                                                            <CustomText text={it?.price + " AED"} style={{ fontSize: 9.5, }} />
                                                        </View>
                                                        <View style={{  width:Platform.OS == "web" && width > constants.BREAK_POINT_1?(width-700-constants.DRAWER_WIDTH-(((width-constants.DRAWER_WIDTH)*0.1))):responsiveWidth(35) }}>
                                                            <CustomText text={sliceText(i18n.locale == "en" ? it?.title : it?.ar_title, 20)} style={{ fontSize: 9.5, }} />
                                                        </View>
                                                        <View style={{ width:Platform.OS == "web" && width > constants.BREAK_POINT_1?150:responsiveWidth(20) }}>
                                                            <CustomText text={sliceText(i18n.locale == "en" ? it?.city : it?.city, 20)} style={{ fontSize: 9.5, }} />
                                                        </View>
                                                        <View style={{  width:Platform.OS == "web" && width > constants.BREAK_POINT_1?150: responsiveWidth(20) }}>
                                                            <CustomText text={sliceText(it?.status, 20)} style={{ fontSize: 9.5, }} />
                                                        </View>
                                                        <View style={{  width:Platform.OS == "web" && width > constants.BREAK_POINT_1?100: responsiveWidth(15), flexDirection: 'row', justifyContent:i18n.locale == "en" ?'flex-start':'flex-end' }}>
                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    setCurrent(it)
                                                                    setModalVisible(true)
                                                                }}
                                                            >
                                                                <Feather name="edit" size={15} color={"#fcce00"} />
                                                            </TouchableOpacity>
                                                            <TouchableOpacity
                                                                onPress={() => onDelete(it?.id)}
                                                                style={{ marginLeft: 10 }}>
                                                                <AntDesign name="delete" size={15} color={"red"} />
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                )
                                            })
                                        }
                                    </View>
                                </ScrollView>
                            ) : (
                                <CustomText text={i18n.t('NO_LISTING_FOUND')} style={{ textAlign: 'center', marginTop: 10 }} />
                            )
                        }
                    </View>
                    {
                        listing?.length > 0 && (
                            <ScrollView>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, justifyContent: 'center' }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (page != 1) {
                                                setPage(page - 1)
                                            }
                                        }}
                                    >
                                        <View style={{ width: 35, height: 35, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center' }}>
                                            <AntDesign name={"left"} color={"gray"} size={15} />
                                        </View>
                                    </TouchableOpacity>
                                    {Array.from({ length: Math.ceil((listing.length / COUNT)) }).map((it, i) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => setPage(i + 1)}
                                            >
                                                <View style={{ width: 35, height: 35, borderRadius: 35, backgroundColor: (page == i + 1) ? "white" : 'lightgray', justifyContent: 'center', alignItems: 'center', marginLeft: 6 }}>
                                                    <CustomText text={i + 1} style={{ color: 'gray', textAlign: 'center' }} />
                                                </View>
                                            </TouchableOpacity>
                                        )
                                    })}
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (page != Math.ceil((listing.length / COUNT))) {
                                                setPage(page + 1)
                                            }
                                        }}
                                    >
                                        <View style={{ width: 35, height: 35, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', marginLeft: 6 }}>
                                            <AntDesign name={"right"} color={"gray"} size={15} />
                                        </View>
                                    </TouchableOpacity>
                                    {
                                        selectedList?.length > 0 && (
                                            <View style={{ position: 'absolute', right: 10 }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        onDeleteMult()
                                                    }}
                                                    style={{ padding: 5, paddingVertical: 8, borderRadius: 20, backgroundColor: '#e3e3e4', width: 90, marginLeft: 5, borderWidth: 1, borderColor: 'lightgray' }}>
                                                    <CustomText text={i18n.t('PROFILE_BTN1') + (deletLoading?`...`:` (${selectedList.length})`)} style={{ textAlign: 'center', fontSize: 10 }} />
                                                </TouchableOpacity>
                                            </View>
                                        )
                                    }
                                </View>
                            </ScrollView>
                        )
                    }
                </View>
            </View>
            {modalVisible && (
                <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)} size={"full"}>
                    <Modal.Content minH={"xl"} style={{ borderRadius: 25 }}>
                        <TouchableOpacity onPress={() => setModalVisible(false)} style={{ position: 'absolute', top: 15, right: 15, zIndex: 2 }}>
                            <AntDesign name='close' size={25} color={"gray"} />
                        </TouchableOpacity>
                        <Modal.Body>
                            <ScrollView>
                                <CreateListing update={current} cb={() => setModalVisible(false)} getLisitng={getLisitng} />
                            </ScrollView>
                        </Modal.Body>
                    </Modal.Content>
                </Modal>
            )}
        </View >
    )
}

function mapStateToProps({ user, garageListing }) {
    return { user, garageListing }
}
export default connect(mapStateToProps, actions)(MyListing)
const styles = StyleSheet.create({})