import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { View, TouchableOpacity } from "react-native";
import * as actions from "../redux/actions"
import { connect } from "react-redux";
import Loader from "../components/Loader";
import i18n from "i18n-js";
import TextInput from "../components/TextInput"
import CustomHeading from "../components/Heading";
import CustomText from "../components/CustomText";
import { FormControl, Select, CheckIcon, Toast } from "native-base";
import DropDownPicker from 'react-native-dropdown-picker';
import ListImages from "./ListImages";
import removeTags from "../utils/removeTags"

const THUMB_RADIUS = 12;
function Search(props) {
    const garage = props?.user?.garage
    const update = props?.update
    const cb = props?.cb
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);


    const [loading, setLoading] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [step, setStep] = useState(1)
    const [list, setList] = useState()
    const [fields, setFields] = useState({
        category: [],
        subCategory: [],
        subSubCategory: [],
        type: [],
        make: [],
        model: [],
        variant: [],
        title: "",
        arabicTitle: "",
        des: "",
        arabicDes: "",
        price: "",
        transmissions:[]
    })

    useEffect(() => {
        if (update) {
            setList(update)
            setFields({
                category: update?.relation_pivots?.categories,
                subCategory: update?.relation_pivots?.subCategories,
                subSubCategory: update?.relation_pivots?.subSubCategories,
                type: update?.relation_pivots?.genres,
                make: update?.relation_pivots?.makes,
                model: update?.relation_pivots?.models,
                variant: update?.relation_pivots?.variants,
                title: update?.title,
                arabicTitle: update?.ar_title,
                des: removeTags(update?.description),
                arabicDes: removeTags(update?.ar_description),
                price: update?.price?.toString(),
            })
        }
    }, [update])
    useEffect(() => {
        setLoading(true)
        Promise.all([props.getCategories(), props.getCars(), props.getTransmissions()])
            .then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (fields.category?.length > 0) {
            setFields({ ...fields, subCategory: [] })
            props.getSubCategories(fields.category, true)
        }
    }, [fields.category])

    useEffect(() => {
        if (fields.subCategory?.length > 0) {
            setFields({ ...fields, subSubCategory: [] })
            props.getSubSubCategories(fields.subCategory, true)
        }
    }, [fields.subCategory])

    useEffect(() => {
        if (fields.make?.length > 0) {
            props.getTypes(fields.make, true)
            setFields({ ...fields, type: [], model: [], variant: [] })
            // props.getModels(fields.make)
        }
    }, [fields.make])

    useEffect(() => {
        if (fields.type?.length > 0) {
            props.getModels(fields.make, fields.type, true)
            setFields({ ...fields, model: [], variant: [] })
        }
    }, [fields.type])

    useEffect(() => {
        if (fields.model?.length > 0) {
            props.getYears(fields.model, true)
            setFields({ ...fields, variant: [] })
        }
    }, [fields.model])

    function onSubmit() {
        setSubmit(true)
        if (fields.title &&
            // fields.arabicTitle &&
            fields.des &&
            // fields.arabicDes &&
            fields.category.length > 0 &&
            fields.subCategory?.length > 0 &&
            fields.subSubCategory?.length > 0 &&
            fields.make?.length > 0 &&
            fields.type?.length > 0 &&
            // fields.model?.length>0 && 
            // fields.variant?.length && 
            fields.price &&
            fields.transmissions?.length>0
        ) {
            setLoading(true)
            const payload = {
                "user_id": props?.user?.user?.id,
                "title": fields.title,
                "status": "pending",
                "description": `<p>${fields.des}</p>`,
                "ar_title": fields.arabicTitle,
                "ar_description": `<p>${fields.arabicDes}</p>`,
                "categories": fields.category,
                "genres": fields.type,
                "subCategories": fields.subCategory,
                "subSubCategories": fields.subSubCategory,
                "makes": fields.make,
                "models": fields.model,
                "variants": fields.variant,
                "city": garage?.seller?.city,
                "price": fields.price,
                "phone": garage?.phone,
                "start_year": "",
                "end_year": "",
                "address": garage?.seller.address,
                "active": true,
                "images": [],
                // "transmissions":fields.transmissions
            }
            if (list?.id) {
                props.updateListing({ ...payload, id: list?.id })
                    .then((res) => {
                        props?.getLisitng()
                        setList(res?.data?.listing)
                        setLoading(false)
                        Toast.show({ title: "Successfully updated" })
                        setSubmit(false)
                        setStep(2)
                    })
                    .catch(err => {
                        console.log("ee",err)
                        setLoading(false)
                    })
            } else {
                props.createListing(payload)
                    .then((res) => {
                        setList(res?.data?.listing)
                        setLoading(false)
                        Toast.show({ title: "Successfully created" })
                        setSubmit(false)
                        setStep(2)
                    })
                    .catch(err => {
                        console.log('ee',err)
                        setLoading(false)
                    })
            }
        }
    }


    const renderFilter = () => {
        return (
            <View style={{ width: '85%', alignSelf: 'center' }}>
                <CustomHeading text={i18n.t(update ? 'UPDATE_LISTING' : 'CREATE_NEW_LISTING')} style={{ marginTop: 7.5, marginBottom: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} />
                <CustomText bold={true} style={{ marginVertical: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} text={i18n.t('LISTING_DETAILS')} />
                <TextInput
                    value={fields.title}
                    error={submit && !fields.title}
                    onChangeText={(v) => setFields({ ...fields, title: v })}
                    style={{ marginTop: 8 }}
                    placeholder={i18n.t('TITILE')}
                    borderColor={"lightgray"}
                />
                <TextInput
                    value={fields.arabicTitle}
                    // error={submit && !fields.arabicTitle}
                    onChangeText={(v) => setFields({ ...fields, arabicTitle: v })}
                    style={{ marginTop: 8 }}
                    placeholder={i18n.t('AR_TITILE')}
                    borderColor={"lightgray"}
                />
                <TextInput
                    value={fields.des}
                    error={submit && !fields.des}
                    onChangeText={(v) => setFields({ ...fields, des: v })}
                    style={{ marginTop: 8 }}
                    placeholder={i18n.t('DES')}
                    borderColor={"lightgray"}
                    multiline={6}
                />
                <TextInput
                    value={fields.arabicDes}
                    // error={submit && !fields.arabicDes}
                    onChangeText={(v) => setFields({ ...fields, arabicDes: v })}
                    style={{ marginTop: 8 }}
                    placeholder={i18n.t('AR_DES')}
                    borderColor={"lightgray"}
                    multiline={6}
                />
                <CustomText bold={true} style={{ marginVertical: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} text={i18n.t('SERV_CAT')} />
                <View>
                    <DropDownPicker
                        open={open1}
                        value={fields.category?.map(it => it)}
                        multiple={true}
                        placeholder={i18n.t('S3')}
                        items={[
                            { value: 'selectAll', label: i18n.t('SELECT_ALL') },
                            ...props.categories?.map(item => ({ value: item.id, label: i18n.locale == "en" ? item.title : item.ar_title }))
                        ]}
                        setOpen={setOpen1}
                        mode="BADGE"
                        listMode="MODAL"
                        onSelectItem={(v) => {
                            // Check if 'Select All' is selected
                            if (v?.some(it => it?.value === 'selectAll')) {
                                // Select all categories if 'Select All' is chosen
                                const allCategories = props.categories.map(item => item.id);
                                setFields({ ...fields, category: allCategories });
                            } else {
                                // Normal selection for individual categories
                                setFields({ ...fields, category: v?.map(it => it?.value) });
                            }
                        }}
                        textStyle={{
                            fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                        }}
                        textc
                        containerStyle={{ paddingTop: 0 }}
                        style={{
                            borderColor: (submit && !fields.category?.length > 0) ? "red" : "lightgray",
                            borderRadius: 30,
                            minHeight: 45,
                            marginVertical: 4
                        }}
                        badgeStyle={{
                            backgroundColor: 'lightgray'
                        }}
                        badgeDotColors={"black"}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: '45%' }}>
                        <View>
                            <DropDownPicker
                                disabled={!fields.category?.length > 0}
                                open={open2}
                                value={fields.subCategory?.map(it => it)}
                                multiple={true}
                                placeholder={i18n.t('S5')}
                                items={[
                                    { value: 'selectAll', label: i18n.t('SELECT_ALL') },
                                    ...props.subCategories?.map(item => ({
                                        value: item.id,
                                        label: i18n.locale == "en" ? item.title : item.ar_title
                                    }))
                                ]}
                                setOpen={setOpen2}
                                mode="BADGE"
                                listMode="MODAL"
                                onSelectItem={(v) => {
                                    // Check if 'Select All' is selected
                                    if (v?.some(it => it?.value === 'selectAll')) {
                                        // Select all subcategories if 'Select All' is chosen
                                        const allSubCategories = props.subCategories.map(item => item.id);
                                        setFields({ ...fields, subCategory: allSubCategories });
                                    } else {
                                        // Normal selection for individual subcategories
                                        setFields({ ...fields, subCategory: v?.map(it => it?.value) });
                                    }
                                }}
                                textStyle={{
                                    fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                                }}
                                textc
                                containerStyle={{ paddingTop: 0 }}
                                style={{
                                    borderColor: (submit && !fields.subCategory?.length > 0) ? "red" : "lightgray",
                                    borderRadius: 30,
                                    minHeight: 45,
                                    marginVertical: 4,
                                    backgroundColor: !fields.category?.length > 0 ? '#f2f2f2' : 'white'
                                }}
                                badgeStyle={{
                                    backgroundColor: 'lightgray'
                                }}
                                badgeDotColors={"black"}
                            />
                        </View>
                    </View>
                    <View style={{ width: '45%' }}>
                        <View>
                            <DropDownPicker
                                disabled={!fields.subCategory?.length > 0}
                                open={open3}
                                value={fields.subSubCategory?.map(it => it)}
                                multiple={true}
                                items={[
                                    { value: 'selectAll', label: i18n.t('SELECT_ALL') },
                                    ...props.subSubCategories?.map(item => ({
                                        value: item.id,
                                        label: i18n.locale == "en" ? item.title : item.ar_title
                                    }))
                                ]}
                                setOpen={setOpen3}
                                mode="BADGE"
                                placeholder={i18n.t('S6')}
                                listMode="MODAL"
                                onSelectItem={(v) => {
                                    // Check if 'Select All' is selected
                                    if (v?.some(it => it?.value === 'selectAll')) {
                                        // Select all sub-subcategories if 'Select All' is chosen
                                        const allSubSubCategories = props.subSubCategories.map(item => item.id);
                                        setFields({ ...fields, subSubCategory: allSubSubCategories });
                                    } else {
                                        // Normal selection for individual sub-subcategories
                                        setFields({ ...fields, subSubCategory: v?.map(it => it?.value) });
                                    }
                                }}
                                textStyle={{
                                    fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                                }}
                                textc
                                containerStyle={{ paddingTop: 0 }}
                                style={{
                                    borderColor: (submit && !fields.subSubCategory?.length > 0) ? "red" : "lightgray",
                                    borderRadius: 30,
                                    minHeight: 45,
                                    marginVertical: 4,
                                    backgroundColor: !fields.subCategory?.length > 0 ? '#f2f2f2' : 'white'
                                }}
                                badgeStyle={{
                                    backgroundColor: 'lightgray'
                                }}
                                badgeDotColors={"black"}
                            />
                        </View>
                    </View>
                </View>
                <CustomText bold={true} style={{ marginVertical: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} text={i18n.t('S11')} />
                <DropDownPicker
                    open={open4}
                    value={fields.make?.map(it => it)}
                    multiple={true}
                    items={[
                        { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                        ...props.cars?.map(item => ({
                            value: item.id,
                            label: i18n.locale == "en" ? item.title : item.ar_title
                        }))
                    ]}
                    setOpen={setOpen4}
                    placeholder={i18n.t('S12')}
                    mode="BADGE"
                    listMode="MODAL"
                    onSelectItem={(v) => {
                        // Check if 'Select All' is selected
                        if (v?.some(it => it?.value === 'selectAll')) {
                            // Select all car makes if 'Select All' is chosen
                            const allMakes = props.cars.map(item => item.id);
                            setFields({ ...fields, make: allMakes });
                        } else {
                            // Normal selection for individual makes
                            setFields({ ...fields, make: v?.map(it => it?.value) });
                        }
                    }}
                    textStyle={{
                        fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                    }}
                    textc
                    containerStyle={{ paddingTop: 0 }}
                    style={{
                        borderColor: (submit && !fields.make?.length > 0) ? "red" : "lightgray",
                        borderRadius: 30,
                        minHeight: 45,
                        marginVertical: 4
                    }}
                    badgeStyle={{
                        backgroundColor: 'lightgray'
                    }}
                    badgeDotColors={"black"}
                />
                <DropDownPicker
                    disabled={!(fields.make?.length > 0)}
                    open={open5}
                    value={fields.type?.map(it => it)}
                    multiple={true}
                    items={[
                        { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                        ...props.types?.map(item => ({
                            value: item.id,
                            label: i18n.locale == "en" ? item.title : item.ar_title
                        }))
                    ]}
                    setOpen={setOpen5}
                    placeholder={i18n.t('CAR_D_TYPE')}
                    mode="BADGE"
                    listMode="MODAL"
                    onSelectItem={(v) => {
                        // Check if 'Select All' is selected
                        if (v?.some(it => it?.value === 'selectAll')) {
                            // Select all types if 'Select All' is chosen
                            const allTypes = props.types.map(item => item.id);
                            setFields({ ...fields, type: allTypes });
                        } else {
                            // Normal selection for individual types
                            setFields({ ...fields, type: v?.map(it => it?.value) });
                        }
                    }}
                    textStyle={{
                        fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                    }}
                    textc
                    containerStyle={{ paddingTop: 0 }}
                    style={{
                        borderColor: (submit && !fields.type?.length > 0) ? "red" : "lightgray",
                        borderRadius: 30,
                        minHeight: 45,
                        marginVertical: 4,
                        backgroundColor: !fields.make?.length > 0 ? '#f2f2f2' : 'white'
                    }}
                    badgeStyle={{
                        backgroundColor: 'lightgray'
                    }}
                    badgeDotColors={"black"}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: '45%' }}>
                        <DropDownPicker
                            disabled={!(fields.type?.length > 0)}
                            open={open6}
                            value={fields.model?.map(it => it)}
                            multiple={true}
                            items={[
                                { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                                ...props.models?.map(item => ({
                                    value: item.id,
                                    label: i18n.locale == "en" ? item.title : item.ar_title
                                }))
                            ]}
                            setOpen={setOpen6}
                            placeholder={i18n.t('S5')}
                            mode="BADGE"
                            listMode="MODAL"
                            onSelectItem={(v) => {
                                // Check if 'Select All' is selected
                                if (v?.some(it => it?.value === 'selectAll')) {
                                    // Select all models if 'Select All' is chosen
                                    const allModels = props.models.map(item => item.id);
                                    setFields({ ...fields, model: allModels });
                                } else {
                                    // Normal selection for individual models
                                    setFields({ ...fields, model: v?.map(it => it?.value) });
                                }
                            }}
                            textStyle={{
                                fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                            }}
                            textc
                            containerStyle={{ paddingTop: 0 }}
                            style={{
                                borderColor: (submit && !fields.model?.length > 0) ? "red" : "lightgray",
                                borderRadius: 30,
                                minHeight: 45,
                                marginVertical: 4,
                                backgroundColor: !fields.type?.length > 0 ? '#f2f2f2' : 'white'
                            }}
                            badgeStyle={{
                                backgroundColor: 'lightgray'
                            }}
                            badgeDotColors={"black"}
                        />
                    </View>
                    <View style={{ width: '45%' }}>
                        <DropDownPicker
                            disabled={!(fields.model?.length > 0)}
                            open={open7}
                            value={fields.variant?.map(it => it)}
                            multiple={true}
                            items={[
                                { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                                ...props.years?.map(item => ({
                                    value: item.id,
                                    label: i18n.locale == "en" ? item.title : item.ar_title
                                }))
                            ]}
                            setOpen={setOpen7}
                            placeholder={i18n.t('S5')}
                            mode="BADGE"
                            listMode="MODAL"
                            onSelectItem={(v) => {
                                // Check if 'Select All' is selected
                                if (v?.some(it => it?.value === 'selectAll')) {
                                    // Select all variants if 'Select All' is chosen
                                    const allVariants = props.years.map(item => item.id);
                                    setFields({ ...fields, variant: allVariants });
                                } else {
                                    // Normal selection for individual variants
                                    setFields({ ...fields, variant: v?.map(it => it?.value) });
                                }
                            }}
                            textStyle={{
                                fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                            }}
                            textc
                            containerStyle={{ paddingTop: 0 }}
                            style={{
                                borderColor: (submit && !fields.variant?.length > 0) ? "red" : "lightgray",
                                borderRadius: 30,
                                minHeight: 45,
                                marginVertical: 4,
                                backgroundColor: !fields.model?.length > 0 ? '#f2f2f2' : 'white'
                            }}
                            badgeStyle={{
                                backgroundColor: 'lightgray'
                            }}
                            badgeDotColors={"black"}
                        />
                    </View>
                </View>
                <DropDownPicker
                    disabled={!(fields.variant?.length > 0)}
                    open={open8}
                    value={fields.transmissions?.map(it => it)}
                    multiple={true}
                    items={[
                        { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                        ...props.transmissions?.map(item => ({
                            value: item.id,
                            label: i18n.locale == "en" ? item.title : item.ar_title
                        }))
                    ]}
                    setOpen={setOpen8}
                    placeholder={i18n.t('CAR_D_TRANSMISSION')}
                    mode="BADGE"
                    listMode="MODAL"
                    onSelectItem={(v) => {
                        // Check if 'Select All' is selected
                        if (v?.some(it => it?.value === 'selectAll')) {
                            // Select all types if 'Select All' is chosen
                            const allTypes = props.transmissions.map(item => item.id);
                            setFields({ ...fields, transmissions: allTypes });
                        } else {
                            // Normal selection for individual types
                            setFields({ ...fields, transmissions: v?.map(it => it?.value) });
                        }
                    }}
                    textStyle={{
                        fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                    }}
                    textc
                    containerStyle={{ paddingTop: 0 }}
                    style={{
                        borderColor: (submit && !fields.transmissions?.length > 0) ? "red" : "lightgray",
                        borderRadius: 30,
                        minHeight: 45,
                        marginVertical: 4,
                        backgroundColor: !fields.model?.length > 0 ? '#f2f2f2' : 'white'
                    }}
                    badgeStyle={{
                        backgroundColor: 'lightgray'
                    }}
                    badgeDotColors={"black"}
                />
                <TextInput
                    value={fields.price}
                    error={submit && !fields.price}
                    onChangeText={(v) => setFields({ ...fields, price: v })}
                    style={{ marginTop: 8 }}
                    placeholder={i18n.t('PRICE')}
                    borderColor={"lightgray"}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10 }}>
                    <TouchableOpacity
                        onPress={() => {
                            setFields({
                                category: [],
                                subCategory: [],
                                subSubCategory: [],
                                type: [],
                                make: [],
                                model: [],
                                variant: [],
                                title: "",
                                arabicTitle: "",
                                des: "",
                                arabicDes: "",
                                price: "",
                                transmissions:[]
                            })
                        }}
                        style={{
                            backgroundColor: 'white',
                            width: 95,
                            height: 35,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 20,
                            borderWidth: 1,
                            borderColor: 'lightgray',
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.23,
                            shadowRadius: 2.62,
                            elevation: 4,
                        }}
                    >
                        <CustomText style={{ color: 'lightgray', textAlign: 'center' }} text={i18n.t('S16')} />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={onSubmit}
                        style={{
                            backgroundColor: 'black',
                            width: 170,
                            height: 35,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 20,
                            borderWidth: 1,
                            borderColor: 'black',
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.23,
                            shadowRadius: 2.62,
                            elevation: 4,
                        }}
                    >
                        <CustomText style={{ color: 'white', textAlign: 'center' }} text={i18n.t(list?.id ? "UPDATE" : 'NEXT')} />
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
    if (loading) {
        return <Loader />
    }
    if (step == 2) {
        return (
            <>
                <ListImages list={list} setList={setList} setStep={setStep} />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10, width: '85%', alignSelf: 'center' }}>
                    <TouchableOpacity
                        onPress={() => setStep(1)}
                        style={{
                            backgroundColor: 'white',
                            width: 95,
                            height: 35,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 20,
                            borderWidth: 1,
                            borderColor: 'lightgray',
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.23,
                            shadowRadius: 2.62,
                            elevation: 4,
                        }}
                    >
                        <CustomText style={{ color: 'lightgray', textAlign: 'center' }} text={i18n.t('BACK')} />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            update ? cb() : props?.setCurrent("myListings")
                        }}
                        style={{
                            backgroundColor: 'black',
                            width: 170,
                            height: 35,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 20,
                            borderWidth: 1,
                            borderColor: 'black',
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.23,
                            shadowRadius: 2.62,
                            elevation: 4,
                        }}
                    >
                        <CustomText style={{ color: 'white', textAlign: 'center' }} text={i18n.t(update ? "UPDATE" : 'CREATE')} />
                    </TouchableOpacity>
                </View>
            </>
        )
    }
    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: '100%', alignSelf: 'center', flex: 1 }}>
                {renderFilter()}
            </View>
        </View>
    );
}

function mapStateToProps({ categories, subCategories, subSubCategories, types, cars, models, years, list, user, transmissions }) {
    return { categories, subCategories, subSubCategories, types, cars, models, years, list, user, transmissions }
}

export default connect(mapStateToProps, actions)(Search)

const styles = {
    title: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
        marginBottom: 12,
    },
    whiteColor: {
        color: "white",
    },
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        padding: 12,
        flex: 1,
        marginTop: -20,
    },
    card: {
        width: 250,
        height: 150,
        borderWidth: 1,
        borderColor: "gray",
        borderRadius: 10,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
    },
    cardTitle: {
        fontSize: 25,
        marginTop: 12,
    },
    cardBody: {
        fontColor: "gray",
    },
    brandCard: {
        height: 110,
        width: 100,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
        marginHorizontal: 5
    },
    brandTitle: {
        marginTop: 16,
    },
    filterCard: {
        height: 45,
        padding: 12,
        margin: 8,
        marginTop: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
    },
    servicesCard: {
        height: 100,
        padding: 12,
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        flexDirection: "row",
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
    },
    serviceTitle: {
        fontWeight: "bold",
    },
    serviceDescription: {
        marginTop: 6,
    },
    roundedFilters: {
        padding: 12,
        borderRadius: 20,
        borderWidth: 1,
        marginLeft: 6,
    },
    root: {
        width: THUMB_RADIUS * 2,
        height: THUMB_RADIUS * 2,
        borderRadius: THUMB_RADIUS,
        borderWidth: 2,
        borderColor: "#B9C7DB",
        backgroundColor: '#B9C7DB',
    },
    root2: {
        height: 4,
        backgroundColor: '#B9C7DB',
        borderRadius: 2,
    },
    root3: {
        flex: 1,
        height: 4,
        borderRadius: 2,
        backgroundColor: '#7f7f7f',
    },
    root4: {
        width: 8,
        height: 8,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: '#B9C7DB',
        borderLeftWidth: 4,
        borderRightWidth: 4,
        borderTopWidth: 8,
    },
    root5: {
        alignItems: 'center',
        padding: 8,
        backgroundColor: '#4499ff',
        borderRadius: 4,
    },
    text5: {
        fontSize: 16,
        color: '#fff',
    },
    root6: {
        alignItems: 'center',
        padding: 8,
        backgroundColor: '#B9C7DB',
        borderRadius: 4,
    },
    text: {
        fontSize: 16,
        color: '#fff',
    },
};
