import { StyleSheet, Text, View, TouchableOpacity, Image, Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { connect } from 'react-redux'
import * as actions from "../redux/actions"
import Loader from '../components/Loader'
import { image_url } from "../myConfig/api.json"
import {
    FontAwesome,
    MaterialIcons
} from "@expo/vector-icons";
import { ScrollView } from 'native-base'
import CustomButton from '../components/CustomButton'
import CustomHeading from '../components/Heading'
import i18n from 'i18n-js'
import CustomText from '../components/CustomText'

function Profile(props) {

    const [loading, setLoading] = useState(false)

    React.useEffect(() => {
        const unsubscribe = props.navigation.addListener('focus', () => {
            setLoading(true)
            props.getCars().then(() => setLoading(false))
        });

        return unsubscribe;
    }, [props.navigation]);

    return (
        <View style={{ flex: 1 }}>
            <Header
                goBack={true}
                props={props}
                round={true}
                disableSearch={true}
                disableDrawer={true}
                disableNotifications={true}
            />
            <View style={{width:'85%',alignSelf:'center', flex: 1 }}>
                <CustomHeading style={{marginTop:10}} text={i18n.t('PROFILE_TEXT1')}/>
                {/* <Text style={[styles.titleBlack, { marginTop: 12, textTransform: 'capitalize' }]}>
                    Cars
                </Text> */}
                {loading ? (
                    <View style={{ height: 100, justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </View>
                ) : (
                    <ScrollView showsVerticalScrollIndicator={false}>
                        {props.userCars.map((item, i) => (
                            <TouchableOpacity
                                key={i}
                                disabled
                                // onPress={() => props.navigation.navigate("VehicleDetails", item)}
                                style={{
                                    backgroundColor: "white",
                                    flexDirection: "row",
                                    borderRadius: 10,
                                    width: '100%',
                                    marginTop: 10
                                }}
                            >
                                <View style={{ flexDirection: "column", flex: 1, padding: 12 }}>
                                <CustomHeading removeMargin={true} style={{fontSize:14}} text={i18n.locale=="en"?item.name:item?.ar_name} />
                                    <View style={{ flexDirection: "column", marginTop: 12 }}>
                                        <View style={{ flexDirection: "row" }}>
                                        <Image source={require('../assets/Counter.png')} style={{ width:20,height:20}} resizeMode="contain"/>
                                            <View>
                                            <CustomText style={{ ...styles.whiteColor, marginLeft: 4 }} text={item.registration_number}/>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", marginTop: 5 }}>
                                        <Image source={require('../assets/Services.png')} style={{ width:20,height:20}} resizeMode="contain"/>
                                            <View>
                                            <CustomText style={{ ...styles.whiteColor, marginLeft: 4 }} text={i18n.locale=="en"?item.transmission?.title:item.transmission?.ar_title} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 10, flexDirection: 'row' }}>
                                    <TouchableOpacity
                                            onPress={() => props.navigation.navigate('CreateVehicle', {...item,fromPro:true})}
                                            style={{ padding: 5,paddingVertical:11, borderRadius: 20, backgroundColor: 'white', width: 70,borderWidth:1,borderColor:'lightgray' }}>
                                            <CustomText text={i18n.t('PROFILE_BTN2')} style={{  textAlign: 'center', fontSize: 10 }}/>
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            onPress={() => {
                                                props.deleteCar(item.id).then(() => props.getUserCars())
                                            }}
                                            style={{ padding: 5,paddingVertical:11, borderRadius: 20, backgroundColor: '#e3e3e4', width: 90,marginLeft: 5 ,borderWidth:1,borderColor:'lightgray' }}>
                                            <CustomText text={i18n.t('PROFILE_BTN1')} style={{textAlign: 'center', fontSize: 10 }}/>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <Image
                                    source={{uri:image_url+"/variants/"+item?.variant?.image}}
                                    style={{
                                        width:160,
                                        height: 110,
                                        zIndex: 2,
                                        marginRight:10
                                        // marginLeft: "-20%",
                                    }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        ))}
                    </ScrollView>
                )}
            </View>
            <View style={{width:'85%',alignSelf:'center',marginBottom: 30,marginTop:20}}>
            <CustomButton
                text={i18n.t('PROFILE_BTN3')}
                textColor="white"
                loaderColor="white"
                bgColor="black"
                onPress={() => props.navigation.push('CreateVehicle',{fromPro:true})}
            />
            </View>
            {/* <TouchableOpacity
                onPress={() => props.navigation.push('CreateVehicle')}
                style={{ backgroundColor: 'black', width: 60, height: 60, borderRadius: 30, position: 'absolute', right: 10, bottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ color: 'white', fontSize: 12, textAlign: 'center' }}>Add Vehicle</Text>
            </TouchableOpacity> */}
        </View>
    )
}

function mapStateToProps({ userCars }) {
    return { userCars }
}
export default connect(mapStateToProps, actions)(Profile)
const styles = StyleSheet.create({
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    title: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    whiteColor: {
        color: "black",
        fontSize:11
    },
})