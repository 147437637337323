import React, { useState, useRef, useEffect } from "react";
import { Text, View, Image, Dimensions, KeyboardAvoidingView, Platform } from "react-native";
import CustomButton from "../components/CustomButton";
import AppIntroSlider from "react-native-app-intro-slider";
import * as WebBrowser from "expo-web-browser";
import * as Facebook from "expo-auth-session/providers/facebook";
import { LoginManager } from "react-native-fbsdk-next";
import axios from "axios";
import { ScrollView, useToast } from "native-base";
import { connect } from "react-redux";
import * as actions from "../redux/actions/index"
import Login from "./login";
import ForgetPassword from "./ForgetPassword";
import SignUp from "./signUp";
import CustomText from "../components/CustomText";
import i18n from 'i18n-js';
import Loader from "../components/Loader";
import { image_url } from "../myConfig/api.json"
import AsyncStorage from "@react-native-async-storage/async-storage"
import Gearz from "../myConfig/gearz"
import { TouchableOpacity } from "react-native-gesture-handler";
import {
  GoogleSignin,
  statusCodes,
  isErrorWithCode
} from '@react-native-google-signin/google-signin';
import constants from "../myConfig/constants";

const { height } = Dimensions.get('window')

WebBrowser.maybeCompleteAuthSession();
function Auth({ navigation, user, login, getLogin }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(true)
  const [images, setImages] = useState([])
  const [forget, setForget] = useState(false)


  const [slide, setSlide] = useState(0)
  const slider = useRef()

  useEffect(() => {
    GoogleSignin.configure({});
  }, []);
  const [fRequest, fResponse, fPromptAsync] = Facebook.useAuthRequest({
    clientId: "711382760671415",
    // responseType: ResponseType.Code,
    redirectUri: 'https://auth.expo.io/@codingeagle/gearz',
    scopes: ['public_profile', 'email'],
  }, { useProxy: true });


  const [getActiveCat, setActiveCat] = useState("user");

  React.useEffect(() => {
    setImgLoading(true)
    getLogin().then((res) => {
      const imgss = JSON.parse((i18n.locale == "en" ? res.data?.page.content : res.data?.page.ar_content))
      setImages(imgss ? imgss : [])
      setImgLoading(false)
    })
  }, [])


  React.useEffect(() => {
    if (fResponse?.type === "success") {
      const { authentication } = fResponse;
      console.log('fresponse success 108')
      console.log(fResponse)
      axios
        .get(
          `https://graph.facebook.com/me?access_token=${authentication.accessToken}&fields=id,name,email`
        )
        .then(function (response) {
          const userDetails = response.data;
          // console.log(userDetails,userDetails);
          setLoading(true)
          AsyncStorage.setItem('user', JSON.stringify(userDetails))
          console.warn(userDetails);
          Gearz.defaults.headers.common['Authorization'] = 'Bearer ' + authentication.accessToken;
          // login({ email: userDetails.email, source: "facebook", name: userDetails.name }).then(() => setLoading(false))
          // dispatch({ type: USER_DATA, data: userDetails });
          // navigation.navigate("CreateVehicle");
        })
        .catch((error) => {
          toast.show({
            title: "Something went wrong",
            status: "error",
            description: error,
          });
        });
    }
    if (fResponse?.type === "error") {
      toast.show({
        title: "Something went wrong",
        status: "error",
        description: error,
      });
    }
  }, [fResponse]);

  const checkAndRedirect = () => {
    LoginManager.logInWithPermissions(["public_profile"]).then(
      function (result) {
        if (result.isCancelled) {
          console.log("Login cancelled");
        } else {
          console.log(
            "Login success with permissions: " +
            result.grantedPermissions.toString()
          );
        }
      },
      function (error) {
        console.log("Login fail with error: " + error);
      }
    );
  };


  const _renderItem = ({ item, index }) => {
    return (
      <View key={index} style={styles.slide}>
        <Image
          source={{ uri: image_url + "/pages/" + item }}
          style={[
            Platform.OS == "web" ? {
              height: 450,
              width: 550
            } : styles.image
          ]}
        />
      </View>
    );
  };

  const signIn = async () => {
    try {
      await GoogleSignin.signOut()
      await GoogleSignin.hasPlayServices();
      const userInfo = await GoogleSignin.signIn();
      setLoading(true)
      login({ email: userInfo?.user?.email, source: "google", name: userInfo?.user?.name }).then(() => setLoading(false))
    } catch (error) {
      if (isErrorWithCode(error)) {
        switch (error.code) {
          case statusCodes.SIGN_IN_CANCELLED:
            // user cancelled the login flow
            break;
          case statusCodes.IN_PROGRESS:
            // operation (eg. sign in) already in progress
            break;
          case statusCodes.PLAY_SERVICES_NOT_AVAILABLE:
            // play services not available or outdated
            break;
          default:
          // some other error happened
        }
      } else {
        // an error that's not related to google sign in occurred
      }
    }
  };

  if (imgLoading) {
    return <Loader />
  }
  return (
    <View style={[
      { flex: 1, backgroundColor: "white" },
      Platform.OS == "web" && {
        width: constants.MOBILE_WIDTH,
        alignSelf: 'center'
      }
    ]}>
      <KeyboardAvoidingView behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <ScrollView showsVerticalScrollIndicator={false} bounces={false}>

          {/* Top Toggle Menu */}
          <View style={[
            { flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", marginTop: 30 },
            Platform.OS != "web" && { flex: 1 }
          ]}>
            <TouchableOpacity onPress={() => setActiveCat('user')} style={{ width: "100%", borderTopLeftRadius: 20, borderBottomLeftRadius: 20, backgroundColor: getActiveCat == 'user' ? "black" : 'white', borderColor: "lightgray", borderWidth: 1, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, width: 140 }}><CustomText text={i18n.t('USER')} style={{ textAlign: "center", color: getActiveCat == "user" ? "white" : 'black' }} /></TouchableOpacity>
            <TouchableOpacity onPress={() => setActiveCat('garage')} style={{ width: "100%", borderTopRightRadius: 20, borderBottomRightRadius: 20, backgroundColor: getActiveCat == 'garage' ? "black" : 'white', borderColor: "lightgray", borderWidth: 1, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, width: 140 }}><CustomText text={i18n.t('GARAGE')} style={{ textAlign: "center", color: getActiveCat == "garage" ? "white" : 'black' }} /></TouchableOpacity>

          </View>


          {/* Top Toggle Menu */}

          <AppIntroSlider
            style={{ height: height / 2 }}
            activeDotStyle={{ backgroundColor: "black", marginTop: "4%" }}
            dotStyle={{
              backgroundColor: "white",
              borderColor: "black",
              borderWidth: 0.3,
              marginTop: "4%",
            }}
            // onSlideChange={(data)=>{
            //   setSlide(data)
            // }}
            keyExtractor={(item) => item}
            ref={slider}
            showSkipButton={false}
            showNextButton={false}
            showDoneButton={false}
            renderItem={_renderItem}
            data={images}
          />
          <View style={[
            { minHeight: height / (Platform.OS == "web" ? 1.75 : 2), backgroundColor: 'black', borderTopRightRadius: 46, borderTopLeftRadius: 46 },
            Platform.OS != "web" && { flex: 1 }
          ]}>
            {(!forget && getActiveCat == 'user') && (
              <View style={{ flexDirection: "row", width: '70%', alignSelf: 'center', marginTop: 20 }}>
                <CustomButton
                  // text="Google"
                  textColor="black"
                  bgColor="white"
                  onPress={signIn}
                  loading={loading && !fResponse}
                  img={require("../assets/cars/Google.png")}
                  addShadow={true}
                  addFlex={true}
                  style={{ height: 30, marginRight: 30 }}
                />
                <CustomButton
                  // text="Facebook"
                  textColor="black"
                  bgColor="white"
                  img={require("../assets/cars/Facebook.png")}
                  onPress={checkAndRedirect}
                  addFlex={true}
                  loading={loading && fResponse}
                  addShadow={true}
                  style={{ height: 30, marginLeft: 30 }}
                />
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 12,
              }}
            >
              {!forget && (
                <>
                  <View style={styles.grayline} />
                  <View>
                    <CustomText
                      text={getActiveCat == "user" ? i18n.t('LOGIN_TEXT1') : i18n.t('LOGIN_TEXTGARAGE')}
                      style={{ color: 'white', paddingHorizontal: 12, marginTop: 10 }}
                    />
                  </View>
                  <View style={styles.grayline} />
                </>
              )}
            </View>
            {slide == 0 ? (
              forget ? (
                <ForgetPassword
                  setForget={setForget}
                />
              ) : (
                <Login
                  setForget={setForget}
                  changeSlide={() => {
                    setSlide(1)
                    // slider?.current.goToSlide(1)
                  }} />
              )
            ) : getActiveCat == 'user' ? <SignUp changeSlide={() => {
              setSlide(0)
              // slider?.current.goToSlide(0)
            }} /> : <SignUp garage={true} changeSlide={() => {
              setSlide(0)
              // slider?.current.goToSlide(0)
            }} />}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}

function mapStateToProps({ user }) {
  return { user }
}

export default connect(mapStateToProps, actions)(Auth)


const styles = {
  heading: {
    fontSize: 35,
    color: "white",
    fontWeight: "bold",
  },
  subheading: {
    color: "gray",
    fontSize: 20,
  },
  slide: {
    flex: 0.8,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    flex: 1,
    width: '100%'
  },
  text: {
    marginTop: 20,
    lineHeight: 20,
    alignSelf: "center",
    textAlign: "center",
  },
  title: {
    fontSize: 36,
    marginTop: "10%",
    alignSelf: "center",
    textAlign: "center",
  },
  grayline: {
    backgroundColor: "#c0c0c0",
    flex: 0.4,
    height: 1
  },
  graytext: {
    color: "#c0c0c0",
    marginRight: 12,
    marginLeft: 12,
    fontWeight: "bold",
  },
};