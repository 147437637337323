import { StyleSheet, Image, View, FlatList, Dimensions, Platform, useWindowDimensions } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import * as actions from "../redux/actions/index"
import { connect } from 'react-redux'
import { image_url } from "../myConfig/api.json"
import i18n from "i18n-js";
import constants from '../myConfig/constants'
import DragScrollWrapper from '../components/DragScrollWrapper'

function Slider({ getBanners }) {
    const { width } = useWindowDimensions()
    const [index, setIndex] = useState(0)
    const sliderRef = useRef(null)
    const [banners, setBanners] = useState([])

    useEffect(() => {
        getBanners()
            .then(res => {
                setBanners(res?.data?.banners)
            })
    }, [])

    const handleScroll = (event) => {
        const offsetX = event.nativeEvent.contentOffset.x
        const currentIndex = Math.round(offsetX / (width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0)))
        setIndex(currentIndex)
    }

    function renderItem({ item }) {
        return (
            <View style={[
                { width: width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) },
                {
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            ]}>
                <Image
                    style={{
                        ...styles.img,
                        width: width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) - 60,
                        height: (Platform.OS == "web" && width > constants.BREAK_POINT_1) ? 350 : 190,
                    }}
                    resizeMode="contain"
                    source={{ uri: `${image_url}/banners/${item?.name}` }}
                />
            </View>
        )
    }

    return (
        <>
            <View style={[
                styles.sliderCon,
                {
                    height: (Platform.OS == "web" && width > constants.BREAK_POINT_1) ? 360 : 200
                },
                Platform.OS != "web" && {
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            ]}>
                {
                    Platform.OS == "web" ? (
                        <DragScrollWrapper pagingEnabled={true}>
                            <FlatList
                                data={banners}
                                ref={sliderRef}
                                renderItem={renderItem}
                                horizontal={true}
                                inverted={i18n.locale == "en" ? false : true}
                                showsHorizontalScrollIndicator={false}
                                pagingEnabled={true}
                                keyExtractor={(item, index) => index.toString()}
                                onScroll={handleScroll}
                                scrollEventThrottle={16}
                            />
                        </DragScrollWrapper>
                    ) : (
                        <FlatList
                            data={banners}
                            ref={sliderRef}
                            renderItem={renderItem}
                            horizontal={true}
                            inverted={i18n.locale == "en" ? false : true}
                            showsHorizontalScrollIndicator={false}
                            pagingEnabled={true}
                            keyExtractor={(item, index) => index.toString()}
                            onScroll={handleScroll}
                            scrollEventThrottle={16}
                        />
                    )
                }
            </View>
            <View style={[
                styles.dotCon,
                Platform.OS == "web" && {
                    alignSelf: 'center'
                }
            ]}>
                {banners.map((_, i) => (
                    <View
                        key={i}
                        style={[
                            styles.dot,
                            {
                                backgroundColor: i === index ? "#000" : "lightgray"
                            }
                        ]}
                    />
                ))}
            </View>
        </>
    )
}

export default connect(null, actions)(Slider)

const styles = StyleSheet.create({
    sliderCon: {
        width: '100%',
        // marginTop: 10,
        position: 'relative',
    },
    img: {
        borderRadius: 20,
        backgroundColor: "white",
        resizeMode: 'cover',
    },
    dotCon: {
        // position: 'absolute',
        flexDirection: 'row',
        marginTop: 12,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dot: {
        width: Platform.OS == "web" ? 10 : 12,
        height: Platform.OS == "web" ? 10 : 12,
        borderRadius: 6,
        marginHorizontal: 5,
        backgroundColor: 'lightgray'
    },
})
