import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, TouchableOpacity, Platform, useWindowDimensions } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign, EvilIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import constants from "../myConfig/constants";
export default function Header(props) {
  const navigation=useNavigation()
  const {width}=useWindowDimensions()
  const { title, disableSearch, disableDrawer, disableNotifications, goBack } =
    props;
  return (
    <View style={{ padding: 12, backgroundColor: "black",borderBottomRightRadius:props.round?15:0,borderBottomLeftRadius:props.round?15:0 }}>
      {title && (
        <Image
          source={require("../assets/logo/logo_basic.png")}
          style={{ height: 30, width: 100, alignSelf: "center" }}
          resizeMode="contain"
        />
      )}
      <View style={{ flexDirection: "row" }}>
        {goBack ? (
          <TouchableOpacity
            onPress={() => navigation.goBack()}
          >
            <Ionicons name="chevron-back-circle" size={24} color="white" />
          </TouchableOpacity>
        ) : (
          (!disableDrawer && !(Platform.OS=="web" && width>constants.BREAK_POINT_1)) && (
            <TouchableOpacity
              onPress={() => props.navigation.navigation.toggleDrawer()}
            >
              <FontAwesome name="bars" size={24} color="white" />
            </TouchableOpacity>
          )
        )}
        {title && <Text style={styles.title}>{title}</Text>}
        {!title && (
          <Image
            source={require("../assets/logo/logo_basic.png")}
            style={styles.imgTitle}
            resizeMode="contain"
          />
        )}
        {!disableSearch && (
          <TouchableOpacity
            onPress={() => props.navigation.navigation.navigate("Search")}
          >
            <AntDesign name="search1" size={24} color="white" />
          </TouchableOpacity>
        )}
        {/* {!disableNotifications && (
          <EvilIcons
            name="user"
            size={32}
            color="white"
            style={{ marginLeft: 12 }}
          />
        )} */}
      </View>
    </View>
  );
}

const styles = {
  title: {
    flex: 1,
    alignSelf: "center",
    textAlign: "center",
    fontSize: 25,
    // fontWeight: "bold",
    marginTop: 12,
    color: "white",
  },
  imgTitle: {
    flex: 1,
    alignSelf: "center",
    // textAlign: "center",
    height: 30,
    width: 100,
    marginRight:25,
    alignSelf: "center",
  },
};
