import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, ScrollView, StyleSheet,Platform } from "react-native";
import Header from "../components/Header";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CustomButton from "../components/CustomButton";
import { TextInput } from "react-native-paper";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { image_url } from "../myConfig/api.json"
import Loader from "../components/Loader"
import { TouchableOpacity } from "react-native-gesture-handler";
import { FlatList, Toast } from "native-base";
import CustomHeading from "../components/Heading";
import { FormControl, Select, Container, CheckIcon, WarningOutlineIcon, Center, NativeBaseProvider } from "native-base";
import i18n from "i18n-js";
import CustomText from "../components/CustomText";
import TextInput1 from "../components/TextInput";
import constants from "../myConfig/constants";

function CreateVehicle(props) {
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [make_id, setMakeId] = useState("")
  const [submit, setSubmit] = useState(false)

  const [fields, setFields] = useState({
    model_id: "",
    registration_number: "",
    transmission: "",
    // registration_year: "",
    variant_id: "",
    transmission_id: "",
    genre_id: ""
  })

  useEffect(() => {
    if (props.route.params?.id) {
      setFields(props.route.params)
      setMakeId(props.route.params?.make_id)
    }
    setLoading(true)


    Promise.all([props.getCars(), props.getTransmissions()])
      .then(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (make_id) {
      props.getTypes(make_id)
      setFields({
        ...fields,
        genre_id: "",
        model_id: "",
        variant_id: "",
        transmission_id: ""
      })
    }
  }, [make_id])

  useEffect(() => {
    if (fields.genre_id) {
      // alert(fields.genre_id)
      props.getModels(make_id, fields.genre_id)
      setFields({
        ...fields,
        model_id: "",
        variant_id: "",
        transmission_id: ""
      })
    }
  }, [fields.genre_id])

  useEffect(() => {
    if (fields.model_id) {
      props.getYears(fields.model_id)
      setFields({
        ...fields,
        variant_id: "",
        transmission_id: ""
      })
    }
  }, [fields.model_id])

  function getTransmissions() {
    if (fields.variant_id && props.years?.length > 0) {
      return props?.years?.filter(it => it.id == fields.variant_id)[0]?.transmissions
    }
    return []
  }

  const checkAndRedirect = () => {

    setSubmit(true)
    if ((props.models.length > 0 ? (fields.model_id) : true) && (props.years.length > 0 ? fields.variant_id : true) && fields.transmission_id && fields.registration_number && fields.genre_id && fields.genre_id && make_id) {
      setBtnLoading(true)
      props.createCar({ ...fields, make_id }, props.goBack, props.route.params?.id).then(() => {
        setBtnLoading(false)
        if (props.goBack) {
          props.getUserCars()
          if (props.route.params?.id) {
            Toast.show({ title: "Successfully Updated" })
          } else {
            Toast.show({ title: "Successfully added" })
          }
          props.navigation.goBack()
        }
      }).catch((err) => {
        console.log(err.response?.data)
        setBtnLoading(false)
        Toast.show({ title: "Something goes wrong!", color: 'red' })
      })
    }
  }
  if (loading) {
    return <Loader />
  }
  return (
    <View style={[
      { flex: 1 },
      Platform.OS=="web" && {
        width:constants.MOBILE_WIDTH,
        alignSelf:'center',
        backgroundColor:'white'
      }
    ]}>
      <Header
        props={props}
        disableSearch={true}
        disableDrawer={true}
        round={true}
        goBack={props.goBack}
        disableNotifications={true}
      />
      <View style={{ flex: 1 }}>
        <View style={{ width: '85%', alignSelf: 'center' }}>
          <CustomHeading
            style={{ marginVertical: 20 }}
            text={i18n.t('CAR_D_TITLE')}
          />
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {/* BRAND NAMES */}
          {(submit && !make_id) ? (
            <View style={{ width: '85%', alignSelf: 'center' }}>
              <CustomText style={{ color: 'red', fontSize: 10 }} text={i18n.t('FIELD_REQ')} />
            </View>
          ) : null}
          <FlatList
            data={props.cars}
            keyExtractor={(item, index) => index?.toString()}
            renderItem={({ item, index }) => {
              return (<TouchableOpacity
                onPress={() => setMakeId(item.id)}
                key={item.id} style={[
                  { ...styles.brandCard, backgroundColor: make_id == item.id ? '#b9c7db' : 'white', },
                  i18n.locale == "en" ? { marginLeft: index == 0 ? 30 : 10 } : { marginRight: index == 0 ? 30 : 10 }
                ]}>
                <Image
                  source={{ uri: `${image_url}/makes/${item.image}` }}
                  style={{ height: 60, width: 60 }}
                  resizeMode="contain"
                />
                <CustomText
                  text={i18n.locale == "en" ? item.title : item.ar_title}
                />
              </TouchableOpacity>)
            }}
            horizontal={true}
            inverted={i18n.locale == "en" ? false : true}
            contentContainerStyle={{ alignSelf: 'center', height: 140 }}
            showsHorizontalScrollIndicator={false}
          />
          {/* <ScrollView horizontal={true} contentContainerStyle={{ alignItems: 'center' }} style={{ height: 140 }} showsHorizontalScrollIndicator={false}>
            {props.cars.map((item, i) => {
              // console.log(image_url + "/makes/" + item.image)
              return (
                <TouchableOpacity
                  onPress={() => setMakeId(item.id)}
                  key={item.id} style={{ ...styles.brandCard, backgroundColor: make_id == item.id ? '#b9c7db' : 'white', borderColor: 'black', borderWidth: make_id == item.id ? 1 : 0, marginLeft: i == 0 ? 30 : 10 }}>
                  <Image
                    source={{ uri: `${image_url}/makes/${item.image}` }}
                    style={{ height: 60, width: 60 }}
                    resizeMode="contain"
                  />
                  <CustomText
                    text={i18n.locale == "en" ? item.title : item.ar_title}
                  />
                </TouchableOpacity>
              )
            })}
          </ScrollView> */}

          {/* VEHICLE DETAILS INP5UTS */}
          <View style={{ width: '85%', alignSelf: 'center' }}>
            <TextInput1
              value={fields.registration_number}
              error={submit && !fields.registration_number}
              onChangeText={(v) => setFields({ ...fields, registration_number: v })}
              placeholder={i18n.t('CAR_D_PLATE_NO')}
              style={{
                marginVertical: 7,
                borderColor: (submit && !fields.registration_number) ? "red" : 'lightgray',
                borderWidth: 1,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowOpacity: 0.27,
                shadowRadius: 4.65,

                elevation: 6,
              }}
            />
            {
              make_id ? (
                <FormControl
                  isRequired
                  isInvalid={submit && !fields.genre_id}
                >
                  <Select
                    selectedValue={Number(fields.genre_id)}
                    onValueChange={(v) => setFields({ ...fields, genre_id: v })}
                    accessibilityLabel={i18n.t('CAR_D_TYPE')}
                    placeholder={i18n.t('CAR_D_TYPE')}
                    placeholderTextColor={"black"}
                    textAlign={i18n.locale == "en" ? "left" : "right"}
                    style={{
                      fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"
                    }}
                    fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
                    marginY={1}
                    fontSize={14}
                    borderRadius={30}
                    backgroundColor="white"
                    height={39.9}
                    shadow={Platform.OS=="web"?"none":"1"}
                    _selectedItem={{
                      bg: "teal.600",
                      endIcon: <CheckIcon size={5}
                      />
                    }}>
                    {props.types.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                  </Select>
                </FormControl>
              ) : null
            }
            {(props.models.length > 0 && fields.genre_id) ? (<FormControl
              isRequired
              isInvalid={submit && !fields.model_id}
            >
              <Select
                selectedValue={Number(fields.model_id)}
                onValueChange={(v) => setFields({ ...fields, model_id: v })}
                accessibilityLabel={i18n.t('CAR_D_MODEL')}
                placeholder={i18n.t('CAR_D_MODEL')}
                placeholderTextColor={"black"}
                textAlign={i18n.locale == "en" ? "left" : "right"}
                style={{
                  fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"
                }}
                fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
                fontSize={14}
                borderRadius={30}
                marginY={1}
                backgroundColor="white"
                height={39.9}
                shadow={Platform.OS=="web"?"none":"1"}
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5}
                  />
                }}>
                {props.models.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} style={{ fontFamily: 'Poppins-Medium' }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
              </Select>
            </FormControl>) : null}
            {props.years.length > 0 && fields.model_id ? (
              <FormControl
                isRequired
                isInvalid={submit && !fields.variant_id}
              >
                <Select
                  selectedValue={Number(fields.variant_id)}
                  onValueChange={(v) => setFields({ ...fields, variant_id: v, transmission_id: "" })}
                  accessibilityLabel={i18n.t('CAR_D_YEAR')}
                  placeholder={i18n.t('CAR_D_YEAR')}
                  placeholderTextColor={"black"}
                  fontSize={14}
                  textAlign={i18n.locale == "en" ? "left" : "right"}
                  style={{
                    fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"
                  }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
                  borderRadius={30}
                  marginY={1}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.years.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            ) : null}

            {(getTransmissions()?.length > 0 && fields.variant_id) && (
              <FormControl
                isRequired
                isInvalid={submit && !fields.transmission_id}
              >
                <Select
                  selectedValue={Number(fields.transmission_id)}
                  onValueChange={(v) => setFields({ ...fields, transmission_id: v })}
                  accessibilityLabel={i18n.t('CAR_D_TRANSMISSION')}
                  placeholder={i18n.t('CAR_D_TRANSMISSION')}
                  placeholderTextColor={"black"}
                  textAlign={i18n.locale == "en" ? "left" : "right"}
                  style={{
                    fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"
                  }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
                  fontSize={14}
                  borderRadius={30}
                  marginY={1}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {getTransmissions()?.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            )}

          </View>
          {/* <TextInput
            label="License Plate"
            error={submit && !fields.registration_number ? true : false}
            onChangeText={(v) => setFields({ ...fields, registration_number: v })}
            value={fields.registration_number}
            placeholder="RJY 346"
            activeUnderlineColor="black"
            style={styles.inputStyles}
          />
          <TextInput
            label="Model No"
            error={submit && !fields.model_id ? true : false}
            onChangeText={(v) => setFields({ ...fields, model_id: v })}
            value={fields.model_id}
            placeholder="RJY 346"
            activeUnderlineColor="black"
            style={styles.inputStyles}
          />
          <TextInput
            error={submit && !fields.registration_year ? true : false}
            onChangeText={(v) => setFields({ ...fields, registration_year: v })}
            value={fields.registration_year}
            label="Registeration Year"
            placeholder="2015"
            activeUnderlineColor="black"
            style={styles.inputStyles}
          />
          <TextInput
            error={submit && !fields.transmission ? true : false}
            onChangeText={(v) => setFields({ ...fields, transmission: v })}
            value={fields.transmission}
            label="Transmission"
            placeholder="Automatic"
            activeUnderlineColor="black"
            style={styles.inputStyles}
          /> */}






          {/* <TextInput
            label="Mileage (KM)"
            placeholder="14000"
            activeUnderlineColor="black"
            style={styles.inputStyles}
          />
          <TextInput
            label="Owner"
            placeholder="First"
            activeUnderlineColor="black"
            style={styles.inputStyles}
          /> */}
        </ScrollView>
        <View style={{ marginBottom: 15, width: '85%', alignSelf: 'center' }}>
          <CustomButton
            text={(props.route.params?.id) ? i18n.t('Update') : i18n.t('CAR_D_BTN')}
            textColor="white"
            loading={btnLoading}
            loaderColor="white"
            bgColor="black"
            onPress={checkAndRedirect}
          />
        </View>
        {!(props.route.params?.fromPro) ? (
          <View style={{ marginBottom: 30, width: '85%', alignSelf: 'center' }}>
            <CustomButton
              text={i18n.t('CAR_D_BTN2')}
              textColor="white"
              loaderColor="white"
              bgColor="black"
              onPress={() => {
                props.saveToken("")
                props.logOut()
              }
              }
            />
          </View>
        ) : null}
      </View>
    </View>
  );
}

function mapStateToProps({ cars, types, transmissions, models, years }) {
  return { cars, types, transmissions, models, years }
}

export default connect(mapStateToProps, actions)(CreateVehicle)

const styles = StyleSheet.create({
  title: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
    marginBottom: 12
  },
  whiteColor: {
    color: "white",
  },
  titleBlack: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
  },
  body: {
    backgroundColor: "#f0f0f0",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    padding: 12,
    flex: 1,
    marginTop: -20,
  },
  card: {
    width: 250,
    height: 150,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 10,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: 25,
    marginTop: 12,
  },
  cardBody: {
    color: "gray",
  },
  brandCard: {
    height: 120,
    width: 110,
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    marginHorizontal: 10
  },
  brandTitle: {
    marginTop: 16,
  },
  inputStyles: { marginTop: 12, backgroundColor: "#f0f0f0" },
})
