import AsyncStorage from "@react-native-async-storage/async-storage"
import { Actionsheet } from "native-base"
import Gearz from "../../myConfig/gearz"
import { CAR_LISTING, GET_BLOCK, GET_CARS, GET_CAT, GET_FEATURES, GET_GARAGE_LISTING, GET_LISTING_DETAIL, GET_MODELS, GET_NOTIFICATIONS, GET_REVIEWS, GET_SEARCH_HISTORY, GET_SUB_CAT, GET_SUB_SUB_CAT, GET_TRANSMISSION, GET_TYPES, GET_USER_CARS, GET_VENDORS, GET_VENDOR_DETAILS, GET_VENDOR_LISTING, GET_YEARS, LOGIN, REG_CAR, SEARCH_LIST } from "./types"

export const login = (data) => async (dispatch) => {
  return Gearz.post(`/social-login`, data).then((res) => {
    AsyncStorage.setItem('user', JSON.stringify(res.data))
    Gearz.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
    dispatch({
      type: LOGIN,
      payload: res.data
    })
  })
}

export const forgetPassword = (data) => async (dispatch) => {
  return Gearz.post(`/reset-password`, data)
}

export const sendOTP = (data) => async (dispatch) => {
  return Gearz.post(`/resend-otp`, data)
}

export const verifyOTP = (data) => async (dispatch) => {
  return Gearz.post(`/verify-otp`, data)
}

export const signUp = (data) => async (dispatch) => {
  return Gearz.post(`/user-register`, data).then((res) => {
    AsyncStorage.setItem('user', JSON.stringify(res.data))
    Gearz.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;

    if (res.data.user.type == "seller") {
      let errorMsg = {
        success: false,
        message: "Thank you for registering with us! We are currently reviewing your profile to ensure that everything is in order. This process typically takes a short amount of time, and we appreciate your patience."
      };
      dispatch({
        type: LOGIN,
        payload: errorMsg
      })
    }
    else {
      if (res?.data?.user?.type == "seller") {
        return Gearz.post(`/get-sellers?page=1`, {
          garage_name: res.data?.user?.name
        })
          .then(res2 => {
            const garage = res2?.data?.sellers?.data?.find(it => it.id == res?.data?.user?.id)
            dispatch({
              type: LOGIN,
              payload: { ...res.data, garage }
            })
          })
      } else {
        dispatch({
          type: LOGIN,
          payload: res.data
        })
      }
    }


  })
}

export const userLogin = (data) => async (dispatch) => {
  return Gearz.post(`/login`, data).then((res) => {

    if (res.data.user.type == "seller" && res.data.user.status != "approved") {
      let errorMsg = {
        success: false,
        message: "Your Account Needs Approval"
      };
      dispatch({
        type: LOGIN,
        payload: errorMsg
      })
    }
    else {
      AsyncStorage.setItem('user', JSON.stringify(res.data))
      Gearz.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
      if (res?.data?.user?.type == "seller") {
        return Gearz.post(`/get-sellers?page=1`, {
          garage_name: res.data?.user?.name
        })
          .then(res2 => {
            const garage = res2?.data?.sellers?.data?.find(it => it.id == res?.data?.user?.id)
            dispatch({
              type: LOGIN,
              payload: { ...res.data, garage }
            })
          })
      } else {
        dispatch({
          type: LOGIN,
          payload: res.data
        })
      }
    }


  }).catch(err => {
    dispatch({
      type: LOGIN,
      payload: err.response.data
    })
  })
}



export const setUser = (cb, loadingOff, showMessage) => async dispatch => {
  Gearz.interceptors.response.use(
    response => {
      if (response.status === 200) {
        return response;
      }
    },
    error => {
      if (error.response && error.response.status === 401) {
        showMessage();
        cb();
      }
      return Promise.reject(error);
    },
  );

  const data = await AsyncStorage.getItem('user');
  const token = JSON.parse(data)?.token

  if (token) {
    Gearz.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return Gearz
      .post('/verify-token')
      .then(res => {
        console.log('myres', res.data);
        if (res?.data?.user?.type == "seller") {
          return Gearz.post(`/get-sellers?page=1`, {
            garage_name: res.data?.user?.name
          })
            .then(res2 => {
              const garage = res2?.data?.sellers?.data?.find(it => it.id == res?.data?.user?.id)
              dispatch({
                type: LOGIN,
                payload: { ...res.data, token, garage }
              })
              loadingOff();

            })
        } else {
          dispatch({
            type: LOGIN,
            payload: { ...res.data, token }
          })
          loadingOff();
        }
      })
      .catch(err => {
        loadingOff();
      })
    // .catch(err => {
    //   loadingOff();
    // });
  } else {
    loadingOff();
  }
};

export const refreshAuth = () => async dispatch => {
  const data = await AsyncStorage.getItem('user');
  const token = JSON.parse(data)?.token

  if (token) {
    Gearz.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return Gearz
      .post('/verify-token')
      .then(res => {
        if (res?.data?.user?.type == "seller") {
          return Gearz.post(`/get-sellers?page=1`, {
            garage_name: res.data?.user?.name
          })
            .then(res2 => {
              const garage = res2?.data?.sellers?.data?.find(it => it.id == res?.data?.user?.id)
              dispatch({
                type: LOGIN,
                payload: { ...res.data, token, garage }
              })
            })
        } else {
          dispatch({
            type: LOGIN,
            payload: { ...res.data, token }
          })
        }
      })
  }
};

export const logOut = () => async dispatch => {
  AsyncStorage.removeItem('user');
  dispatch({
    type: LOGIN,
    payload: {},
  });
};


export const getUserCars = () => async dispatch => {
  return Gearz.get(`/all-user-cars`).then((res) => {
    // console.log("mycars",res.data)
    dispatch({
      type: GET_USER_CARS,
      payload: res.data.cars
    })
  })
};


export const getCars = () => async dispatch => {
  return Gearz.get(`/makes`).then((res) => {
    dispatch({
      type: GET_CARS,
      payload: res.data.makes
    })
  })
};

export const createCar = (data, back, id) => async dispatch => {
  if (id) {
    return Gearz.post(`/update-car/${id}`, data)
  } {
    return Gearz.post(`/register-car`, data).then((res) => {
      if (!back) {
        dispatch({
          type: REG_CAR
        })
      }
    })
  }
};

export const getCategories = () => async dispatch => {
  return Gearz.get(`/category/list`).then((res) => {
    dispatch({
      type: GET_CAT,
      payload: res.data.categories
    })
  })
};

export const getSubCategories = (id, array) => async dispatch => {
  if (array) {
    const reqs = id.map(it => {
      return Gearz.get(`/category/${it}/get-sub-categories`)
    })
    const responses = await Promise.all(reqs)
    const finalData = responses.reduce((acc, it) => {
      acc = [...acc, ...it.data?.sub_categories]
      return acc
    }, [])
    dispatch({
      type: GET_SUB_CAT,
      payload: finalData
    })
  } else {
    return Gearz.get(`/category/${id}/get-sub-categories`).then((res) => {
      dispatch({
        type: GET_SUB_CAT,
        payload: res.data.sub_categories
      })
    })
  }
};

export const getSubSubCategories = (id, array) => async dispatch => {
  if (array) {
    const reqs = id.map(it => {
      return Gearz.get(`/sub-category/${it}/sub-sub-categories`)
    })
    const responses = await Promise.all(reqs)
    const finalData = responses.reduce((acc, it) => {
      acc = [...acc, ...it.data?.sub_categories]
      return acc
    }, [])
    dispatch({
      type: GET_SUB_SUB_CAT,
      payload: finalData
    })
  } else {
    return Gearz.get(`/sub-category/${id}/sub-sub-categories`).then((res) => {
      dispatch({
        type: GET_SUB_SUB_CAT,
        payload: res.data.sub_categories
      })
    })
  }
};

export const getFeature = (name) => async dispatch => {
  return Gearz.get(`/featured-listings/${name}`)
};

export const getBlocks = (id) => async dispatch => {
  return Gearz.get(`/display/blocks`).then((res) => {
    dispatch({
      type: GET_BLOCK,
      payload: res.data.blocks
    })
  })
};

export const deleteCar = (id) => async dispatch => {
  return Gearz.delete(`/delete-car/${id}`)
};


export const getVendors = (data) => async dispatch => {
  return Gearz.get(`/sellers/list`, {
    params: data
  }).then((res) => {
    dispatch({
      type: GET_VENDORS,
      payload: res.data.sellers
    })
  })
};


export const getVendorDetails = (id) => async dispatch => {
  return Gearz.get(`/seller/${id}/show`).then((res) => {
    dispatch({
      type: GET_VENDOR_DETAILS,
      payload: res.data.seller
    })
  })
};


export const searchList = (data) => async dispatch => {
  return Gearz.get(`/search-listings`, {
    params: data
  }).then((res) => {
    dispatch({
      type: SEARCH_LIST,
      payload: res.data.listings
    })
    console.warn(res);
  })
};

// export const getCarListing = (data) => async dispatch => {
//   return Gearz.get(`/relevent-listings/`,{
//     params:data
//   }).then((res)=>{
//     dispatch({
//       type:CAR_LISTING,
//       payload:res.data.listings
//     })
//     console.warn(res);
//   })
// };
export const getCarListing = (data) => async dispatch => {
  return Gearz.get(`/relevent-listings`, {
    params: data
  }).then((res) => {
    dispatch({
      type: CAR_LISTING,
      payload: res.data.listings
    })
  })
};

export const getSearchHistory = (data) => async dispatch => {
  return Gearz.get(`/get-searches`).then((res) => {
    dispatch({
      type: GET_SEARCH_HISTORY,
      payload: res.data.searches
    })
  })
};

export const getNotifications = (data) => async dispatch => {
  return Gearz.get(`/get-all-notifications`).then((res) => {
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data.notifications
    })
  })
};


export const getVendorListing = (id) => async dispatch => {
  return Gearz.get(`/seller/${id}/listings`).then((res) => {
    dispatch({
      type: GET_VENDOR_LISTING,
      payload: res.data.listings
    })
  })
};

export const saveToken = (fcm_token) => async dispatch => {
  return Gearz.post(`/fcm-token/save`, {
    fcm_token
  })
};


export const getListingDetails = (id, seller) => async dispatch => {
  if (seller) {
    return Gearz.get(`/seller/${id}/listings`).then((res) => {
      dispatch({
        type: GET_LISTING_DETAIL,
        payload: res.data.listing
      })
    })
  } else {
    return Gearz.get(`/listing/${id}/view`).then((res) => {
      dispatch({
        type: GET_LISTING_DETAIL,
        payload: res.data.listing
      })
    })
  }
};

export const getReviews = (id, seller) => async dispatch => {
  if (seller) {
    return Gearz.get(`/seller/${id}/reviews`).then((res) => {
      dispatch({
        type: GET_REVIEWS,
        payload: res.data.reviews
      })
    })
  } else {
    return Gearz.get(`/get-listing-reviews/${id}`).then((res) => {
      dispatch({
        type: GET_REVIEWS,
        payload: res.data.reviews
      })
    })
  }
};

export const addReview = (data) => async dispatch => {
  return Gearz.post(`/review/store`, data)
};


export const getTypes = (id, array) => async dispatch => {
  if (array) {
    return Gearz.get(`/genres?make=${id[0]}`).then(res => {
      dispatch({
        type: GET_TYPES,
        payload: res.data.genres
      })
    })
  } else {
    return Gearz.get(`/genres?make=${id}`).then(res => {
      dispatch({
        type: GET_TYPES,
        payload: res.data.genres
      })
    })
  }
};


export const getModels = (make, id, array) => async dispatch => {
  if (array) {
    var reqs = make.reduce((acc, it, i) => {
      const subReqs = id.map(subIt => {
        return Gearz.get(`/make/${it}/models?genre_id=${subIt}`)
      })
      acc = [...acc, ...subReqs]
      return acc
    }, [])
    const responses = await Promise.all(reqs)
    const finalData = responses.reduce((acc, it) => {
      acc = [...acc, ...it?.data?.models]
      return acc
    }, [])
    dispatch({
      type: GET_MODELS,
      payload: finalData
    })
  } else {
    return Gearz.get(`/make/${make}/models?genre_id=${id}`).then(res => {
      // alert(JSON.stringify(res.data))
      dispatch({
        type: GET_MODELS,
        payload: res.data.models
      })
    })
  }
};


export const getTransmissions = () => async dispatch => {
  return Gearz.get(`/transmissions`).then(res => {
    dispatch({
      type: GET_TRANSMISSION,
      payload: res.data.transmissions
    })
  })
};


export const getYears = (id, array) => async dispatch => {
  // alert(id)
  if (array) {
    const reqs = id.map(it => {
      return Gearz.get(`/model/${it}/variants`)
    })
    const responses = await Promise.all(reqs)
    console.log("sdaf", responses?.map(it => it?.data))
    const finalData = responses.reduce((acc, it) => {
      acc = [...acc, ...it.data?.variants]
      return acc
    }, [])
    dispatch({
      type: GET_YEARS,
      payload: finalData
    })
  } else {
    return Gearz.get(`/model/${id}/variants`).then(res => {
      // console.log("dddd",id,res.data)
      dispatch({
        type: GET_YEARS,
        payload: res.data.variants
      })
    })
  }
};


export const getSplash = () => async dispatch => {
  return Gearz.get(`/mobile/splash-screen`)
};

export const getLogin = () => async dispatch => {
  return Gearz.get(`/mobile/login-screen`)
};

export const getBrands = (id) => async dispatch => {
  return Gearz.get(`/seller/${id}/brands`)
};

export const createListing = (data) => async dispatch => {
  return Gearz.post(`/listing/store`, data)
};

export const updateListing = (data) => async dispatch => {
  return Gearz.post(`/listing/${data?.id}/update`, data)
};

export const getListImages = (id) => async dispatch => {
  return Gearz.post(`/listing/${id}/images`)
};

export const deleteImage = (image_id) => async dispatch => {
  return Gearz.post(`/delete-listing-image`, { image_id })
};

export const uploadListImage = (data) => async dispatch => {
  return Gearz.post(`/listing-images`, data)
};


export const getGarageListing = (data) => async dispatch => {
  return Gearz.post(`/search-listings?page=${data?.page}`, data).then((res) => {
    dispatch({
      type: GET_GARAGE_LISTING,
      payload: res.data
    })
  })
};


export const updateGarageProfile = (data) => async dispatch => {
  return Gearz.post(`/seller-update`, data)
};

export const deleteListing = (id) => async dispatch => {
  return Gearz.post(`/listing/%20${id}%20/delete`)
};

export const getTerms = () => async dispatch => {
  return Gearz.post(`/page-data`,{slug:'terms-and-condition'})
};
export const getBanners = () => async dispatch => {
  return Gearz.post(`/banner/all`)
};

export const sendReport = (data) => async dispatch => {
  return Gearz.post(`/main/report/store`,data)
};