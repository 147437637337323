export default {
    GARAGE_DOCUMENT_DESCRIPTION: "Upload up-to 10 documents in PDF format",
    GARAGE_HEADING: "Garage Logo",
    UPLOAD_BTN: "Upload",
    GARAGE_DESCRIPTION: "Upload logo in JPG, PNG format",
    GARAGE_DOCUMENTS: "Garage documents",
    UPLOAD_ITEMS: "Upload items",
    GARAGE_NAME: "Garage Name",
    LOCATION_LINK: "Location Link",
    ABOUT_GARAGE: "About Garage",
    LOGIN_TEXTGARAGE: "Garage details",
    WELCOME_HEADING: "All garages",
    WELCOME_SUB_HEAD: "at your finger tips",
    WELCOME_DES: "explore hundreds of garages all on your screen. With many services listed, find the deals that best suites your needs",
    WELCOME_BTN: "Let's go!",
    LOGIN_TEXT1: "or use your email",
    LOGIN_TEXT2: "Email",
    ADD_LISTING: "Add Listing",
    LISTING_DASHBOARD: "Listing Dashboard",
    MANAGE_LISTING: "Manage Listing",
    LOGIN_TEXT3: "Password",
    LOGIN_TEXT4: "Forget Password",
    LOGIN_BTN: "LOGIN",
    LOGIN_TEXT5: "Dont have an account?",
    LOGIN_TEXT6: "SignUp here",
    SIGN_TEXT1: "Confirm Password",
    SIGN_BTN: "Sign Up",
    SIGN_TEXT2: "Already have an account?",
    SIGN_TEXT3: "Login here",
    SIGN_TEXT4: "Name",
    SIGN_TEXT5: "Phone No",
    CAR_D_TITLE: "Enter Vehicle Details",
    CAR_D_TYPE: "Type",
    CAR_D_MODEL: "Model",
    CAR_D_YEAR: "Year",
    CAR_D_PLATE_NO: "Plate No",
    CAR_D_TRANSMISSION: "Transmission",
    FIELD_REQ: "Please select",
    CAR_D_BTN: "Apply",
    CAR_D_BTN2: "Logout",
    DRAWER_TEXT1: "Language",
    DRAWER_TEXT2: "Notifications",
    DRAWER_TEXT3: "Logout",
    PROFILE_TEXT1: "Cars",
    PROFILE_BTN1: "Delete",
    PROFILE_BTN2: "Edit",
    PROFILE_BTN3: "Add new",
    DASH_TEXT1: "Looking for",
    DASH_TEXT2: "Promoted",
    DASH_TEXT3: "Most viewed",
    DASH_TEXT4: "Recent searches",
    DASH_TEXT5: "Garage",
    VENDORS: "Vendors",
    VIEW_LIST_TEXT1: "Description",
    VIEW_LIST_TEXT2: "More services from garage",
    VIEW_LIST_TEXT3: "User reviews",
    VIEW_LIST_TEXT4: "Add a review",
    VIEW_LIST_TEXT5: "Rating",
    VIEW_LIST_TEXT6: "Location",
    VIEW_LIST_TEXT7: "Listed Services",
    VIEW_LIST_BTN: "Add",
    NDF: "No Data Found",
    S1: "Keyword",
    S2: "Filters",
    S3: "Main category",
    S5: "Sub",
    S6: "Sub Sub",
    S7: "Search filters",
    S8: "City",
    S9: "Sort by",
    S10: "Price",


    S11: "Vehicle options",
    S12: "Make",
    S13: "Search filters",
    S14: "City",
    S15: "Sort by",
    S16: "Clear all",
    S17: "Change language to arabic",
    S18: "Oldest",
    S19: "Newest",


    CITY1: "Abu Dhabi",
    CITY2: "Al Ain",
    CITY3: "Ajman",
    CITY4: "Dubai",
    CITY5: "Fujairah",
    CITY6: "Sharjah",
    CITY7: "Ras Al Khaimah",
    C19: "See Listed Brand",

    G1: "Categories",
    G2: "Garages",
    G3: "Go to location",
    G4: "Brands",
    G5: "Send OTP",
    G6: "Verify OTP",
    Update: "Update",
    SE1: "Apply",
    REPORT_CUSTOM_TEXT: "Report",

    UPLOAD_LOGO: "Upload logo",
    UPLOAD_DOCS: "Upload docs",
    WELCOME: "Welcome",
    DASHBOARD: "Dashboard",
    MY_LISTING: "My Listings",
    ADD_LISTING: "Add Listings",
    PROFILE: "Profile",
    LISTING: "Listing",
    APPROVED: "Approved",
    PENDING: "Pending",
    TOTAL: "Total",
    CREATE_NEW_LISTING: "Create new listing",
    UPDATE_LISTING: "Update listing",
    LISTING_DETAILS: "Listing details",
    TITILE: "Title",
    AR_TITILE: "Arabic title",
    DES: "Description",
    AR_DES: "Arabic description",
    SERV_CAT: "Service categories",
    PRICE: "Price",
    CANCEL: "Cancel",
    NEXT: "Next",
    LISTING_IMAGE: "Listing image",
    UPDATE: "Update",
    BACK: "Back",
    APPROVED: "Approved",
    PENDING: "pending",
    REJECT: "rejected",
    STATUS: "Status",
    CITY: "City",
    NUM: "Num",
    PRICE: "Price",
    LISTING_TITLE: "Listing title",
    ACTIONS: "Actions",
    PROFILE_DETAILS: "Profile details",
    LOGO: "Logo",
    CREATE: "Create",
    NO_LISTING_FOUND: "No listing found",
    DASHBOARD: "Dashboard",
    SETTINGS: "Settings",
    CONTACT_US: "Contact us",
    TERM_CON: "Terms and conditions",
    CONTACT_DES:"Feel free, to get in touch with us. Provide accurate information, so we can Help you.",
    TYPE_HERE: "Type here",
    SEND: "Send",
    USER: "User",
    GARAGE: "Garage",
    PASS_ERR:"Password does not match",
    SERVICE_BRAND:"Serviced brands",
    SERVICE_CAT:"Service categories",
    SUB_CAT:"Sub categories",
    SUB_SUB_CAT:"Sub sub categories",
    OUR_LATEST:"Our latest updates",
    SELECT_ALL:"Select all",
    ADD_REVIEW:"Add review",


    // add new



































}