export default {
    WELCOME_HEADING: "All garages",
    WELCOME_SUB_HEAD: "at your finger tips",
    WELCOME_DES: "explore hundreds of garages all on your screen. With many services listed, find the deals that best suites your needs",
    WELCOME_BTN: "هيا بنا!",
    LOGIN_TEXT1: "أو باستخدام البريد الإلكتروني",
    LOGIN_TEXT2: "عنوان البريد الإلكتروني",
    LOGIN_TEXT3: "كلمة السر",
    ADD_LISTING: "إضافة قائمة",
    LISTING_DASHBOARD: "قائمة لوحة القيادة",
    MANAGE_LISTING: "إدارة القائمة",
    LOGIN_TEXT4: "نسيت كلمة السر؟",
    LOGIN_BTN: "تسجيل الدخول",
    LOGIN_TEXT5: "ليس لديك حساب؟",
    LOGIN_TEXT6: "سجل حسابك هنا",
    SIGN_TEXT1: "تأكيد كلمة السر",
    SIGN_BTN: "أنشئ الحساب",
    SIGN_TEXT2: "لديك حساب مسجل؟",
    SIGN_TEXT3: "سجل دخولك هنا",
    SIGN_TEXT4: "الاسم",
    SIGN_TEXT5: "رقم الهاتف",
    CAR_D_TITLE: "أدخل بيانات المركبة",
    CAR_D_TYPE: "النوع",
    CAR_D_MODEL: "الطراز",
    CAR_D_YEAR: "السنة",
    CAR_D_PLATE_NO: "رقم اللوحة",
    CAR_D_TRANSMISSION: "ناقل الحركة",
    FIELD_REQ: "الرجاء التحديد",
    CAR_D_BTN: "إضافة جديد",
    CAR_D_BTN2: "تسجيل خروج",
    DRAWER_TEXT1: "اللغة",
    DRAWER_TEXT2: "الإشعارات",
    DRAWER_TEXT3: "تسجيل خروج",
    PROFILE_TEXT1: "المركبات",
    PROFILE_BTN1: "حذف",
    PROFILE_BTN2: "تعديل",
    PROFILE_BTN3: "إضافة جديد",
    DASH_TEXT1: "أبحث عن",
    DASH_TEXT2: "الخدمات المروجة",
    DASH_TEXT3: "الأكثر مشاهدة",
    DASH_TEXT4: "المضاف حديثاً",
    DASH_TEXT5: "ورشة تصليح",
    VENDORS: "ورشات التصليح",
    VIEW_LIST_TEXT1: "الوصف",
    VIEW_LIST_TEXT2: "المزيد من خدمات الورشة",
    VIEW_LIST_TEXT3: "آراء المتعاملين",
    VIEW_LIST_TEXT4: "أضف رأيك",
    VIEW_LIST_TEXT5: "التقييم",
    VIEW_LIST_TEXT6: "الموقع",
    VIEW_LIST_TEXT7: "الخدمات المسجلة",
    VIEW_LIST_BTN: "أضف",
    NDF: "لم يتم العثور على البيانات",
    S1: "كلمة مفتاحية",
    S2: "فلترة",
    S3: "الفئة الرئيسية",
    S5: "الفئة الفرعية",
    S6: "الفئة دون الفرعية",
    S7: "فلاتر البحث",
    S8: "المدينة",
    S9: "ترتيب النتائج",
    S10: "السعر",
    S11: "خيارات المركبة",
    S12: "الشركة المصنعة",
    S13: "فلاتر البحث",
    S14: "المدينة",
    S15: "ترتيب النتائج",
    S16: "محو",
    S17: "تغير اللغة الى العربية",
    S17: "تغيير اللغة الى الانجليزية",
    S18: "الأقدم",
    S19: "الأحدث",
    CITY1: "أبو ظبي",
    CITY2: "العين",
    CITY3: "عجمان",
    CITY4: "دبي",
    CITY5: "الفجيرة",
    CITY6: "الشارقة",
    CITY7: "رأس الخيمة",
    C19: "عرض العلامات التجارية",

    G1: "الفئات",
    G2: "ورشات التصليح",
    G3: "انتقل إلى الموقع",
    G4: "العلامات التجاريه",
    G5: "إرسال OTP",
    G6: 'التحقق من OTP',
    Update: "تعديل",
    SE1: "بحث",
    REPORT_CUSTOM_TEXT: "تبليغ",
    UPLOAD_LOGO: "حمل الشعار",
    UPLOAD_DOCS: "حمل المستندات",
    WELCOME: "أهلاً وسهلاً",
    DASHBOARD: "لوحة التحكم",
    MY_LISTING: "منشوراتي",
    ADD_LISTING: "أضف منشور",
    PROFILE: "ملفي",
    LISTING: "منشور",
    APPROVED: "تمت الموافقة",
    PENDING: "معلقة",
    TOTAL: "إجمالي",
    CREATE_NEW_LISTING: "أنشئ منشور جديد",
    UPDATE_LISTING: "حدث المنشور",
    LISTING_DETAILS: "تفاصيل المنشور",
    TITILE: "عنوان بالإنجليزية",
    AR_TITILE: "عنوان بالعربي",
    DES: "وصف بالإنجليزية",
    AR_DES: "عنوان بالعربي",
    SERV_CAT: "تصنيف الخدمة",
    PRICE: "السعر",
    CANCEL: "إلغاء",
    NEXT: "التالي",
    LISTING_IMAGE: "صور المنشور",
    UPDATE: "تحديث",
    BACK: "العودة",
    APPROVED: "تمت الموافقة",
    PENDING: "معلقة",
    REJECT: "تم الرفق",
    STATUS: "الحالة",
    CITY: "المدينة",
    NUM: "رقم",
    PRICE: "السعر",
    LISTING_TITLE: "عنوان المنشور",
    ACTIONS: "إجراءات",
    PROFILE_DETAILS: "تفاصيل ملفي",
    LOGO: "شعار",
    CREATE: "أنشئ",
    NO_LISTING_FOUND: "لم يتم العثور على أي منشور",
    DASHBOARD: "لوحة التحكم",
    SETTINGS: "الإعدادات",
    CONTACT_US: "تواصل معنا",
    TERM_CON: "الشروط والأحكام",
    CONTACT_DES: "لا تتردد في الاتصال بنا. صف لنا بدقة استفسارك، حتى نتمكن من مساعدتك",
    TYPE_HERE: "اكتب هنا",
    SEND: "إرسال",
    USER: "مستخدم",
    GARAGE: "محل",
    PASS_ERR: "كلمة السر غير متطابقة",
    SERVICE_BRAND: "العلامات التجارية المقدمة",
    SERVICE_CAT: "فئات الخدمات",
    SUB_CAT: "الفئات الفرعية",
    SUB_SUB_CAT: "الفئات الفرعية الفرعية",
    OUR_LATEST: "أحدث تحديثاتنا",
    SELECT_ALL: "اختر الكل",
    ADD_REVIEW:"إضافة مراجعة",
}