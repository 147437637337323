import { StyleSheet, Text, View, TextInput } from 'react-native'
import React from 'react'
import i18n from 'i18n-js'

export default function TextInput1({ error, onChangeText, placeholder, value, secureTextEntry, style, center, multiline,borderColor,disable }) {
  if (multiline) {
    return (
      <View style={{
        backgroundColor: 'white',
        borderRadius: 20,
        justifyContent: 'center',
        paddingHorizontal: 20,
        borderColor: error?'red':borderColor,
        borderWidth: (error || borderColor) ? 1 : 0,
        ...style
      }}>
        <TextInput
          secureTextEntry={secureTextEntry}
          value={value}
          onChangeText={onChangeText}
          multiline={true}
          editable={!disable}
          numberOfLines={multiline}
          placeholder={placeholder}
          style={{ width: '100%', textAlign: center ? "center" : (i18n.locale == "en" ? "left" : "right"), fontFamily: i18n.locale == "en" ? 'Poppins-Medium' : 'GE-SS-Unique-Bold',textAlignVertical:'top',paddingTop:10,outlineStyle:'none' }}
        />
      </View>
    )
  }
  return (
    <View style={{
      backgroundColor: 'white',
      borderRadius: 20,
      height: 40,
      justifyContent: 'center',
      paddingHorizontal: 20,
      borderColor: 'red',
      borderColor: error?'red':borderColor,
      borderWidth: (error || borderColor) ? 1 : 0,
      ...style
    }}>
      <TextInput
        secureTextEntry={secureTextEntry}
        value={value}
        editable={!disable}
        onChangeText={onChangeText}
        placeholder={placeholder}
        style={{ width: '100%', textAlign: center ? "center" : (i18n.locale == "en" ? "left" : "right"), fontFamily: i18n.locale == "en" ? 'Poppins-Medium' : 'GE-SS-Unique-Bold',outlineStyle:'none' }}
      />
    </View>
  )
}

const styles = StyleSheet.create({})