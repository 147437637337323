import React, { useRef, useState, Children, isValidElement } from 'react';
import { View, TouchableOpacity, StyleSheet, ScrollView, FlatList, useWindowDimensions, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons'; // You can use any icon set
import constants from '../myConfig/constants';

// Wrapper component to add scroll controls
const DragScrollWrapper = ({ children, horizontal = true, scrollEnabled = true, pagingEnabled,iconsPadding }) => {
    const scrollViewRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const { width } = useWindowDimensions()
    const scrollForward = () => {
        if (scrollViewRef.current) {
            // For ScrollView
            if (isValidElement(children) && children.type === ScrollView) {
                scrollViewRef.current.scrollTo({
                    x: horizontal ? scrollPosition + (pagingEnabled ? width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) : 800) : 0,
                    y: horizontal ? 0 : scrollPosition + (pagingEnabled ? width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) : 800),
                    animated: true,
                });
            }

            // For FlatList
            else if (isValidElement(children) && children.type === FlatList) {
                scrollViewRef.current.scrollToOffset({
                    offset: scrollPosition + (pagingEnabled ? width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) : 800),
                    animated: true,
                });
            }
        }
    };

    const scrollBackward = () => {
        if (scrollViewRef.current) {
            // For ScrollView
            if (isValidElement(children) && children.type === ScrollView) {
                scrollViewRef.current.scrollTo({
                    x: horizontal ? scrollPosition - (pagingEnabled ? width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) : 800) : 0,
                    y: horizontal ? 0 : scrollPosition - (pagingEnabled ? width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) : 800),
                    animated: true,
                });
            }

            // For FlatList
            else if (isValidElement(children) && children.type === FlatList) {
                scrollViewRef.current.scrollToOffset({
                    offset: scrollPosition - (pagingEnabled ? width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) : 800),
                    animated: true,
                });
            }
        }
    };

    // Scroll listener to track the scroll position for FlatList and ScrollView
    const handleScroll = (event) => {
        const offset = horizontal ? event.nativeEvent.contentOffset.x : event.nativeEvent.contentOffset.y;
        setScrollPosition(offset);
    };

    return (
        <View style={{ ...styles.container, width: width - ((Platform.OS == "web" && width > constants.BREAK_POINT_1) ? constants.DRAWER_WIDTH : 0) }}>
            {/* Scroll buttons */}
            <TouchableOpacity onPress={scrollBackward} style={{ ...styles.scrollButton, left: iconsPadding?iconsPadding:60 }}>
                <View style={styles.view}>
                    <Icon name="chevron-back" size={70} color="rgba(255, 255, 255, 0.8)" />
                </View>
            </TouchableOpacity>

            <TouchableOpacity onPress={scrollForward} style={{ ...styles.scrollButton, right: iconsPadding?iconsPadding:60 }}>
                <View style={styles.view}>
                    <Icon name="chevron-forward" size={70} color="rgba(255, 255, 255, 0.8)" />
                </View>
            </TouchableOpacity>

            {/* Wrap the child ScrollView or FlatList */}
            {React.cloneElement(children, {
                ref: scrollViewRef,
                scrollEnabled,
                onScroll: handleScroll,
                scrollEventThrottle: 16, // Optional: throttle the scroll events for performance
            })}
        </View>
    );
};

// Basic styling
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center'
    },
    scrollButton: {
        position: 'absolute',
        zIndex: 10
    },
    view:{
        width:70,
        height:70,
        backgroundColor:'rgba(0, 0, 0, 0.2)',
        borderRadius:10,
        justifyContent:'center',
        alignItems:'center'
    }
});

export default DragScrollWrapper;
